import React, { useState, useEffect } from 'react';
import { auth, db } from "../../firebase";
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepFour from './steps/StepFour';
import StepFive from './steps/StepFive';
import StepSix from './steps/StepSix';
import StepSeven from './steps/StepSeven';
import StepEight from './steps/StepEight';
import StepNine from './steps/StepNine';
import StepTen from './steps/StepTen';
import StepEleven from './steps/StepEleven';
import StepTwelve from './steps/StepTwelve';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useUID from '../General/useUID';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import './ipa.css';
import Topbar from '../General/Topbar';
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 

function IPAApplication() {
    const [currentStep, setCurrentStep] = useState(1);
    const [uid, subUserUID] = useUID();
    const [canProceed, setCanProceed] = useState(false);
    const [formData, setFormData] = useState({});
    const navigate = useNavigate(); 

    const steps = ['Step One', 'Step Two', 'Step Three', 'Step Four', 'Step Five', 'Step  Six', 'Step Seven', 'Step Eight', 'Step Nine', 'Step Ten', 'Step Eleven', 'Step Twelve'];


    useEffect(() => {
      const initializeApplication = async () => {
          if (uid) {
              const docRef = doc(db, "users", uid, "applications", "ipaApplication");
              const docSnap = await getDoc(docRef);
  
              if (docSnap.exists()) {
                  // Resume an existing application
                  const data = docSnap.data();
                  setCurrentStep(data.currentStep || 1);
              } else {
                  // Start a new application
                  await setDoc(docRef, { started: new Date(), currentStep: 1 }, { merge: true });
                  setCurrentStep(1);
              }
          }
      };
  
      initializeApplication();
  }, [uid]); // Dependency on `uid` to ensure user is loaded
  

    useEffect(() => {
      const lastStep = Number(localStorage.getItem('lastStep'));
      // Ensure the last step is within the valid range of steps
      if (lastStep && lastStep > 0 && lastStep < steps.length) {
          setCurrentStep(lastStep);
      } else {
          localStorage.removeItem('lastStep'); // Reset if out of bounds
          setCurrentStep(1); // Start from the first step
      }
  }, []);
  

    useEffect(() => {
      
        localStorage.setItem('lastStep', currentStep);
    }, [currentStep]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    const prevStep = () => setCurrentStep(Math.max(1, currentStep - 1));

    const nextStep = async () => {
        if (!canProceed && currentStep < steps.length - 1) return; // Prevent proceeding if not allowed, but allow on the success page

        if (uid && currentStep < steps.length - 1) {
            // Only update the currentStep in Firestore if it's not the success step
            const docRef = doc(db, "users", uid, "applications", "ipaApplication");
            await setDoc(docRef, { ...formData, currentStep: currentStep + 1 }, { merge: true });
        }
        setCurrentStep(currentStep + 1);
    };
        
        
        const renderStep = () => {
          if (currentStep === steps.length + 1) {
            // Assuming step.length + 1 is your success step
            submitApplication(); // Call submit function which navigates back to /ipa
            return (
              // You might not need to return anything here if you're redirecting immediately
              <div>Redirecting...</div>
            );
          }      
          switch (currentStep) {
              case 1:
                  return <StepOne nextStep={nextStep} setCanProceed={setCanProceed} />;
              case 2:
                  return <StepTwo nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 3:
                  return <StepThree nextStep={nextStep}  prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 4:
                return <StepFour nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 5:
                return <StepFive nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 6:
                return <StepSix nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 7:
                return <StepSeven nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 8:
                return <StepEight nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 9:
                return <StepNine nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 10:
                return <StepTen nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 11:
                return <StepEleven nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              case 12:
                return <StepTwelve nextStep={nextStep} prevStep={prevStep} setCanProceed={setCanProceed} />;
              default:
                  return <div>Unknown step</div>;
          }
      };

      const submitApplication = async () => {
        if (uid) {
            // Set the currentStep to 12 in Firestore, indicating the application is complete
            const docRef = doc(db, "users", uid, "applications", "ipaApplication");
            await setDoc(docRef, { ...formData, currentStep: 13 }, { merge: true });
            // Optionally, handle any final submission tasks here
            
            navigate('/ipapopularis'); // Redirect the user back to /ipa page
        }
    };
  
      return (
          <div className='IPA-background'>
            <Topbar />
              <div className='IPA-container'>
              <div className='IPA-form'>
              <Stepper style={{paddingTop:"2rem"}} activeStep={currentStep - 1} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
                  {renderStep()}
                  <div>
                {currentStep > 1 && (
                  <Button onClick={prevStep}>Back</Button>
                )}
                {currentStep < steps.length && (
                   <Button onClick={nextStep} disabled={!canProceed}>Next</Button>
        )}
                {currentStep === steps.length && (
            <Button onClick={nextStep} disabled={!canProceed}>Submit</Button>
        )}
              </div>
              </div>
              </div>
          </div>
      );
  }
  
  export default IPAApplication;