import React, { useState } from 'react';

const FAQSection = () => {
    const [selectedId, setSelectedId] = useState(null);

    const handleClick = (id) => {
        setSelectedId(selectedId === id ? null : id);
    }

    const faqs = [
        { id: 1, question: 'What kind of services does Popularis Health provide?', answer: 'Popularis Health provides a comprehensive suite of software tools for healthcare professionals. This includes features for medical billing, patient management, insurance eligibility checks, telehealth services, and even a personalized AI chat assistant.' },
        { id: 2, question: 'How does Popularis Health protect my data?', answer: 'We are committed to data security and HIPAA compliance. All data is encrypted and stored on secure servers. We conduct regular audits and updates to ensure your data\'s safety. Please read our privacy policy for further information about how we keep patient data secure.' },
        { id: 3, question: 'How can AI help in my healthcare practice?', answer: 'Because AI is new, the posibilities are endless. But we have found it useful for general queries and specific searches. No more googling medical questions! We are also using it to power our notes generator for doctors. Check out the notes tool in the patients app.' },
        { id: 4, question: 'Is it free to create an account?', answer: 'Yes. Sign up for a freebie account and get 25 requests for free.' },
        { id: 5, question: 'How does the batch billing work?', answer: 'You select many patients at once or upload an XLSX template with the basic patient information. This allows you to send up to 1,500 claims, eligibility requests, or claims status\' at a time.' },

    ];

    return (
        <div className="FAQ-container">
            <h1>FAQ</h1>
            {faqs.map(({ id, question, answer }) => (
                <div key={id}>
                    <h2 onClick={() => handleClick(id)}>{selectedId === id ? "-" : "+"}{"       "}{question}</h2>
                    {selectedId === id && <p>{answer}</p>}
                </div>
            ))}
        </div>
    );
}

export default FAQSection;
