import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";

import FAQSection from '../subcomponents/FAQ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassDollar, faHeadset, faFileInvoiceDollar, faStickyNote, faStaffSnake, faVideo, faHospital, faHeart, faBrain, faLock, faBoltLightning, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Eclipse from '../assets/eclipse_full.jpg';
import nurse from '../assets/nurse.jpg';
import heroImage from '../assets/heroImage.png';
import patientCamera from '../assets/INSURANCE-CARD.jpg';

import Header from '../subcomponents/Header';
import Footer from '../subcomponents/Footer';
import HeroAnimation from '../subcomponents/HeroAnimation';
import PayerSelection from '../../Payers/PayerSelection';
import Messages from '../subcomponents/MessagesDemo';
import PricingTable from '../subcomponents/PricingTable';

import Patients from '../assets/Macbook_patients.png';
import change from '../assets/Change_Healthcare.png';
import openai from '../assets/OpenAI.png';
import zoom from '../assets/Zoom-Logo.png';
import EncryptionSample from '../subcomponents/EncryptionSample';
import PatientsBalls from '../subcomponents/PatientsBalls';
import FormEditor from '../../PatientFiles/FormEditor/FormEditor/FormEditor';
import { margin } from '@mui/system';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({});
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [tileVisibility, setTileVisibility] = useState({});
  const tileRefs = useRef([]);
  const [email, setEmail] = useState(localStorage.getItem('email') || new URLSearchParams(location.search).get('email') || '');

  const addRef = (element) => {
    if (element && !tileRefs.current.includes(element)) {
      tileRefs.current.push(element);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 500); // .5 seconds delay
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible((prev) => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
        });
      },
      { threshold: 0.1 }
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setTileVisibility((prev) => ({ ...prev, [entry.target.dataset.id]: entry.isIntersecting }));

          // Play/Pause video based on visibility
          if (entry.target.tagName === 'VIDEO') {
            if (entry.isIntersecting) {
              entry.target.play();
            } else {
              entry.target.pause();
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    tileRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const handlePayerSelect = (payer) => {
    // Your logic for handling payer selection
  };

  const handleFormDataChange = (newFormData) => {
    setFormData(newFormData);
  };

  const handleSaveData = async () => {
    return;
  };

  
  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  const handleNavigation = (page) => {
    if (email) {
      if (!validateEmail(email)) {
        alert('Please enter a valid email address.');
      } else {
        navigate(`/${page}?email=${encodeURIComponent(email)}`);
      }
    } else {
      navigate(`/${page}`);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="landing-page">
      <Helmet>
        <title>Popularis Health - Patients and Billing Simplified</title>
        <meta name="description" content="The Full Suite of Clinical and Administrative Tools for Healthcare." />
        <meta name="keywords" content="EMR, Medical Claims, Eligibility, patient eligibility, encrypted medical records, encrypted emr, claim status, Medical billing, Patient management software, Cloud-based EMR solutions, Medical coding, Claims, Patient records, PHI, Popularis, Popularis Health, Healthcare, Intake forms, Patient eligibility, Insurance eligibility" />
        <meta property="og:title" content="Popularis Health - Patients and Billing Simplified." />
        <meta property="og:description" content="Patients and Billing Simplified with Popularis Health. Start sending claims in 5 minutes with our full suite of clinical and administrative tools." />
        <meta property="og:url" content="https://www.popularishealth.com" />
      </Helmet>

      <Header />
      <main className="landing-content">
        <section className="hero-section" ref={el => sectionRefs.current['hero'] = el} id="hero">
          <div className='heroGrid'>
            <div className="hero-container">
              <div className="hero-text">
                <h2 style={{ textAlign:"left" }}className={`hero-title ${isVisible['hero'] ? 'animate' : ''}`}>Practice Management</h2>
                <h2 style={{ textAlign:"left" }}className={`hero-title ${isVisible['hero'] ? 'animate' : ''}`}>Done Right</h2>
                <p style={{ textAlign:"left" }} className={`hero-description ${isVisible['hero'] ? 'animate' : ''}`}>Join other growing healthcare practices utilizing batch billing, instant eligibility, custom intake forms, cloud computing, AI, and more.</p>
                <div className={`cta-buttons ${isVisible['hero'] ? 'animate' : ''} ${isButtonVisible ? 'visible' : 'hidden'}`}>
                  <input 
                    type="email" 
                    placeholder="Email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button style={{ marginTop:"0px", marginBottom:"0px" }} className="cta-button" onClick={() => handleNavigation('signup')}>
                    Try for Free
                  </button>
                  <button style={{  marginTop:"0px", marginBottom:"0px"  }} className="cta-button" onClick={() => handleNavigation('demo')}>
                    Book a Demo
                  </button>
                </div>
                <div className="hero-disclaimer-container" >
                    <p style={{ textAlign:"left" }} className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>Immediate access - No credit card required</p>
                  </div>
              </div>
            </div>
            <div className='heroImageContainer'>
              <img src={heroImage} alt="" className="heroImage" />
            </div>
          </div>
        </section>

        <section className="process-section" ref={el => sectionRefs.current['process'] = el} id="process">
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['process'] ? 'animate' : ''}`}>Simply, we do three things...</h2>
                        <div className="process-steps">
                            {[
                                { title: "Help you manage patients", description: "User friendly patients app with the latest bells and whistles." },
                                { title: "Make it easy to get paid", description: "Send multiple claims and ensure you're going to get paid with realtime eligibility." },
                                { title: "Keep your data safe", description: "All patients and claims data is encrypted use the latest advanced algorithm." },
                            ].map((step, index) => (
                                <div className={`step-3 ${isVisible['process'] ? 'animate' : ''}`} key={index} style={{animationDelay: `${index * 0.2}s`}}>
                                    <span className="step-number">{index + 1}</span>
                                    <h3 className="step-title">{step.title}</h3>
                                    <p className="step-description">{step.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>


        <section className="features-section grey-bg">
          <div className="">
            <section className="section-features">
              <div className="tiles-contents">
                <div className="grid-wrapper">

               
                  <div
                    className={`tile-gateway tile tile-white ${tileVisibility["gateway"] ? "tile-visible" : "tile-animate"}`}
                    data-id="gateway"
                    ref={addRef}
                  >
                    <div className="tile-content">
                        <div className="payerSelectorContainer">
                        <h1 style={{ textAlign:"center" }} className="typography-design-headline-dark">We connect you to the Payer Gateway.</h1>
                        <p style={{ textAlign:"center" }} className="typography-label-alt-dark">3,500 Payers. We connect you to the US Healthcare System. Search below.</p>
                          <PayerSelection onSelect={handlePayerSelect} mode={"website"} />
                        </div>
                      </div>
                    </div>
                  <div
                    className={`tile-patients tile tile-dark ${tileVisibility["patients"] ? "tile-visible" : "tile-animate"}`}
                    data-id="patients"
                    ref={addRef}
                  >
                    <div className="ballContainer">
                      <div className="patients-wrapper">
                        <PatientsBalls />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tile-security tile tile-black ${tileVisibility["security"] ? "tile-visible" : "tile-animate"}`}
                    data-id="security"
                    ref={addRef}
                  >
                    <div className="w-100">
                      <div className="security-wrapper">
                        <FontAwesomeIcon icon={faLock} style={{ height: "48px", margin: "1rem" }} />
                        <h1 style={{textAlign: "center"}} className="typography-design-headline text-center">Zero-Trust Encryption</h1>
                        <p className="typography-label-alt-white text-center">In today's cyber-threat environment, it is critical that all sensitive health information is encrypted. We use the Advanced Encryption Standard (AES) developed by the National Institute of Standards (NIST). Additionally, our encrypted ciphertext requires multiple keys to decrypt, adding to the enigma.</p>
                        <EncryptionSample />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tile-glamour tile tile-white ${tileVisibility["glamour"] ? "tile-visible" : "tile-animate"}`}
                    data-id="glamour"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="glamour-wrapper">
                        <h1 className="typography-design-headline-dark">In a relationship, communication is key.</h1>
                        <div className="messages-wrapper">
                          <Messages triggerAnimation={tileVisibility["glamour"]} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tile-demo tile tile-white ${tileVisibility["demo"] ? "tile-visible" : "tile-animate"}`}
                    data-id="demo"
                    ref={addRef}
                  >
                    <div className="tile-content">
                      <div className="demo-wrapper">
                        <h1 className="typography-design-headline-dark">See Popularis in action on YouTube.</h1>
                        {/* YouTube Embed Code */}
                        <div className="video-responsive">
                          <iframe width="560" height="315" src="https://www.youtube.com/embed/DKn5ix1xaPc?si=3lroJSgO3_MayeWG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tile-tools tile-dark tile pt-4 px-4`}
                    data-id="tools"
                    ref={addRef}
                  >
                    <h1 style={{  marginTop:"10px", marginBottom:"0px"  }} className='typography-design-headline'>Send 1,500 claims at a time.</h1>
                    <p style={{  marginTop:"10px", marginBottom:"20px"  }} className="typography-label-alt-white ">Send single claims, XLSX batch uploads, multiple select from patients, or even for all billable enncounters that your clinical staff has made.</p>
                     <div className={`cta-buttons ${isVisible['hero'] ? 'animate' : ''} ${isButtonVisible ? 'visible' : 'hidden'}`}>
                        <input 
                          type="email" 
                          placeholder="Email" 
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button style={{ marginTop:"0px", marginBottom:"0px" }} className="cta-button" onClick={() => handleNavigation('signup')}>
                          Try for Free
                        </button>
                        <button style={{  marginTop:"0px", marginBottom:"0px"  }} className="cta-button" onClick={() => handleNavigation('demo')}>
                          Book a Demo
                        </button>
                      </div>
                      <div className="hero-disclaimer-container" >
                          <p style={{ textAlign:"left" }} className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>Immediate access - No credit card required</p>
                        </div>
                  </div>

{/* 
                  <div
                    style={{ backgroundImage: `url(${nurse})`, backgroundSize: "cover", backgroundPosition: "center" }}
                    className={`tile-providers tile tile-white ${tileVisibility["providers"] ? "tile-visible" : "tile-animate"}`}
                    data-id="providers"
                    ref={addRef}
                  >
                    <div className="tile-content tile-content-image">
                      <div className="providers-wrapper">
                        <h1 style={{ textAlign:"center" }} className="typography-design-headline">Built for Providers.</h1>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ backgroundImage: `url(${patientCamera})`, backgroundSize: "cover", backgroundPosition: "center" }}
                    className={`tile-patientpic tile tile-white ${tileVisibility["patientpic"] ? "tile-visible" : "tile-animate"}`}
                    data-id="patientpic"
                    ref={addRef}
                  >
                    <div className="tile-content tile-content-image">
                      <div className="patientpic-wrapper">
                        <h1 style={{ textAlign:"center" }} className="typography-design-headline">With Patients in mind.</h1>
                      </div>
                    </div>
                  </div> */}

                
                  <div
                    className={`tile-support tile pt-4 px-4 tile-white ${tileVisibility["support"] ? "tile-visible" : "tile-animate"}`}
                    data-id="support"
                    ref={addRef}
                  >
                    <a href='/support'>
                      <div className="tile-content">
                        <div className="support-wrapper">
                          <div className="support-icon-features">
                            <FontAwesomeIcon style={{ height: "100px" }} icon={faHeadset} />
                          </div>
                          <div>
                            <h1 className="typography-headline">The Popularis Team is always by your side.</h1>
                            <p className="typography-label-alt-dark">US-based support is always just a call, email, or text away.</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="pricing-section white-bg">
          <div className="section-container">
            <h2 className="section-title">Simple, Transparent Pricing</h2>
            <PricingTable />
          </div>
        </section>

        <section className="faq-section grey-bg">
          <div className="section-container">
            <h2 className="section-title">Frequently Asked Questions</h2>
            <FAQSection />
          </div>
        </section>

        <section className="partners-section white-bg">
          <div className="section-container">
            <h2 className="section-title">Trusted Partners</h2>
            <div className="partner-logos">
              <img src={change} alt="Change Healthcare" />
              <img src={zoom} alt="Zoom" />
              <img src={openai} alt="OpenAI" />
            </div>
          </div>
        </section>
      </main>
      <Footer />

      <style jsx>{`
        body {
          overflow-x: hidden;
          max-width: 100%;
          margin: 0px;
        }

        .landing-page {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
          color: #1d1d1f;
          background-color: #fbfbfd;
          -webkit-font-smoothing: antialiased;
        }

        .hero-section {
          background-color: #00316f;
          color: #f5f5f7;
          height: auto;
          display: flex;
          position: relative;
          overflow: hidden;
          text-align: center;
        }

        .hero-container, .section-container {
          max-width: 980px;
          margin: 0 auto;
          padding: 0 22px;
          position: relative;
          z-index: 1;
        }

        .hero-animation-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        .plan-card {
             background-color: #00316f;
             padding: 10px;
             color: #fff;
        }

        .hero-text {
          margin-top: 5vh;
          z-index: 1;
        }

        .hero-title {
          font-size: 72px;
          textAlign: left;
          line-height: 1.05;
          font-weight: 700;
          letter-spacing: -0.015em;
          margin-bottom: 10px;
        }

        .hero-subtitle {
          font-size: 48px;
          textAlign: left;
          line-height: 1.08349;
          font-weight: 600;
          letter-spacing: -.002em;
          margin-bottom: 20px;
        }

        .hero-description {
          font-size: 24px;
          textAlign: left;
          line-height: 1.16667;
          font-weight: 400;
          letter-spacing: .009em;
          margin-bottom: 40px;
        }
        .section-title {
          font-size: 48px;
          line-height: 1.08349;
          font-weight: 600;
          letter-spacing: -.003em;
          text-align: center;
          margin-bottom: 50px;
        }

        .section-description {
          font-size: 21px;
          line-height: 1.381;
          font-weight: 400;
          letter-spacing: .011em;
          text-align: center;
          margin-bottom: 40px;
          color: #86868b;
        }

        .cta-buttons.hidden {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        .cta-buttons.visible {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        .features-section, .patients-section, .tools-section, .payer-section, .communication-section, .pricing-section, .faq-section, .partners-section {
          padding: 30px 0;
        }

        .grey-bg {
          background-color: #f8f8f8;
        }

        .white-bg {
          background-color: #ffffff;
        }

        .feature-cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
        }

        .feature-card {
          background: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 20px;
          width: calc(33.333% - 20px);
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .feature-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }

        .feature-icon {
          font-size: 48px;
          color: #0071e3;
          margin-bottom: 20px;
        }

        .feature-card h3 {
          font-size: 24px;
          line-height: 1.16667;
          font-weight: 600;
          letter-spacing: .009em;
          margin-bottom: 10px;
        }

        .feature-card p {
          font-size: 17px;
          line-height: 1.47059;
          font-weight: 400;
          letter-spacing: -.022em;
        }

        .feature-image {
          max-width: 100%;
          height: auto;
          border-radius: 18px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }

        .partner-logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 50px;
        }

        .partner-logos img {
          height: 50px;
          object-fit: contain;
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate {
          animation: fadeInUp 0.6s ease-out forwards;
        }

        @media only screen and (max-width: 1068px) {
          .hero-title {
            font-size: 56px;
          }

          .hero-subtitle {
            font-size: 36px;
          }

          .hero-description {
            font-size: 21px;
          }

          .section-title {
            font-size: 40px;
          }
        }

        @media only screen and (max-width: 734px) {
          .hero-title {
            font-size: 44px;
          }

          .hero-subtitle {
            font-size: 28px;
          }

          .hero-description {
            font-size: 18px;
          }

          .cta-button {
            font-size: 16px;
            padding: 10px 20px;
          }

          .section-title {
            font-size: 32px;
          }

          .feature-cards {
            flex-direction: column;
            align-items: center;
          }

          .feature-card {
            width: 100%;
          }

          .partner-logos {
            flex-direction: column;
            gap: 30px;
          }
        }

        .cta-buttons.hidden {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        .cta-buttons.visible {
          opacity: 1;
          visibility: visible;
          animation: fadeIn 3.5s ease;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .tile-setup {
          grid-area: setup;
        }

        .tile-tools {
          grid-area: tools;
        }

        .tile-patients {
          grid-area: patients;
        }

        .tile-billing {
          grid-area: billing;
        }

        .tile-providers {
          grid-area: providers;
        }

        .tile-patientpic {
          grid-area: patientpic;
        }

        .tile-glamour {
          grid-area: glamour;
        }

        .tile-security {
          grid-area: security;
        }

         .tile-demo {
          grid-area: demo;
        }

        .tile-metal {
          grid-area: metal;
        }

        .tile-IPA {
          grid-area: IPA;
        }

        .tile-gateway {
          grid-area: gateway;
        }

        .tile-continuity {
          grid-area: continuity;
        }

        .tile-support {
          grid-area: support;
        }

        .tile-dark {
          color: #f5f5f7;
          background: #00316f;
        }

        .tile-white {
          background: #f1f1f1;
        }

        .tile-actually-white {
           background: #fbfbfb;
        }

        .tile-black {
          color: #f5f5f7;
          background: #000000;
        }

        .tile {
          position: relative;
          border-radius: 20px;
          overflow: hidden;
          margin: 0 auto;
          display: flex;
          width: 100%;
          box-sizing: border-box;
        }

        .tile-content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          width: 100%;
          padding: 25px !important;
        }

        .tile-content-hextials {
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 5rem;
          margin-bottom: 0rem;
        }

        .section-features {
          padding: 0px 25px;
          width: 61.8%;
          max-width: 1140px;
          margin: auto;
        }
        .grid-wrapper {
          display: grid;
          grid-template-rows: 500px 400px 660px 500px 380px 250px  200px;
          grid-auto-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-gap: 25px;
          grid-template-areas:
            "gateway gateway gateway gateway gateway gateway"
            "patients patients patients patients patients patients"
            "security security security security security security"
            "glamour glamour glamour glamour glamour glamour"
            "demo demo demo demo demo demo"
            "tools tools tools tools tools tools"
            "support support support support support support";
        }

        @media (max-width: 768px) {
          .grid-wrapper {
            grid-template-rows: auto;
            grid-auto-rows: auto;
            grid-template-areas:
              "gateway"
              "patients"
              "billing"
              "security"
              "glamour"
              "demo"
              "tools"
              "support";
          }

          .tile {
            grid-column: span 6;
          }
        }

        .tiles-contents {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          max-width: 1390px;
        }

        .typography-design-headline {
          font-size: 32px;
          line-height: 1.0625;
          font-weight: 600;
          letter-spacing: -0.009em;
          color: #ffffff;
          padding-top: .25rem;
          padding-bottom: .25rem;
          text-align: left;
        }

        .typography-design-headline-dark {
          font-size: 32px;
          line-height: 1.0625;
          font-weight: 600;
          letter-spacing: -0.009em;
          color: #000000;
          padding-top: .25rem;
          padding-bottom: .25rem;
          text-align: left;
        }

                .landing-page {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
                    color: #1d1d1f;
                    background-color: #fbfbfd;
                    -webkit-font-smoothing: antialiased;
                }

          

                .hero-container, .section-container {
                    max-width: 980px;
                    margin: 0 auto;
                    padding: 0 22px;
                    position: relative;
                    z-index: 1;
                }

                .hero-animation-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                }

                .hero-title {
                    font-size: 72px;
                    line-height: 1.05;
                    font-weight: 700;
                    letter-spacing: -0.015em;
                    margin-bottom: 10px;
                    margin-top: 10px;
                }

                .hero-subtitle {
                    font-size: 48px;
                    line-height: 1.08349;
                    font-weight: 600;
                    letter-spacing: -.002em;
                    margin-bottom: 20px;
                }

                .hero-description {
                    font-size: 24px;
                    line-height: 1.16667;
                    font-weight: 400;
                    letter-spacing: .009em;
                    margin-bottom: 40px;
                }
                .section-title {
                    font-size: 48px;
                    line-height: 1.08349;
                    font-weight: 600;
                    letter-spacing: -.003em;
                    text-align: center;
                    margin-bottom: 50px;
                }

                .section-description {
                    font-size: 21px;
                    line-height: 1.381;
                    font-weight: 400;
                    letter-spacing: .011em;
                    text-align: center;
                    margin-bottom: 40px;
                    color: #86868b;
                }

                .cta-buttons.hidden {
                  opacity: 0;
                  visibility: hidden;
                  transition: opacity 0.5s ease, visibility 0.5s ease;
                }

                .cta-buttons.visible {
                  opacity: 1;
                  visibility: visible;
                  transition: opacity 0.5s ease, visibility 0.5s ease;
                }

                .features-section, .patients-section, .tools-section, .payer-section, .communication-section, .pricing-section, .faq-section, .partners-section {
                    padding-top: 30px;
                    padding-bottom: 70px;
                }

                .grey-bg {
                    background-color: #f8f8f8;
                }

                .white-bg {
                    background-color: #ffffff;
                }

                .feature-cards {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 20px;
                }

                .feature-card {
                    background: #fff;
                    border: 1px solid #e0e0e0;
                    border-radius: 10px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    padding: 20px;
                    width: calc(33.333% - 20px);
                    text-align: center;
                    transition: transform 0.3s ease, box-shadow 0.3s ease;
                }

                .feature-card:hover {
                    transform: translateY(-10px);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
                }

                .feature-icon {
                    font-size: 48px;
                    color: #0071e3;
                    margin-bottom: 20px;
                }

                .feature-card h3 {
                    font-size: 24px;
                    line-height: 1.16667;
                    font-weight: 600;
                    letter-spacing: .009em;
                    margin-bottom: 10px;
                }

                .feature-card p {
                    font-size: 17px;
                    line-height: 1.47059;
                    font-weight: 400;
                    letter-spacing: -.022em;
                }

                .feature-image {
                    max-width: 100%;
                    height: auto;
                    border-radius: 18px;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
                }

                .partner-logos {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 50px;
                }

                .partner-logos img {
                    height: 50px;
                    object-fit: contain;
                }

                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .animate {
                    animation: fadeInUp 0.6s ease-out forwards;
                }

                @media only screen and (max-width: 1068px) {
                    .hero-title {
                        font-size: 56px;
                    }

                    .hero-subtitle {
                        font-size: 36px;
                    }

                    .hero-description {
                        font-size: 21px;
                    }

                    .section-title {
                        font-size: 40px;
                    }
                }

                @media only screen and (max-width: 734px) {
                    .hero-title {
                        font-size: 44px;
                    }

                    .hero-subtitle {
                        font-size: 28px;
                    }

                    .hero-description {
                        font-size: 18px;
                    }

                    .cta-button {
                        font-size: 16px;
                        padding: 10px 20px;
                    }

                    .section-title {
                        font-size: 32px;
                    }

                    .feature-cards {
                        flex-direction: column;
                        align-items: center;
                    }

                    .feature-card {
                        width: 100%;
                    }

                    .partner-logos {
                        flex-direction: column;
                        gap: 30px;
                    }
                }
                .pricing-table {
                  display: flex;
                  justify-content: space-around;
                  flex-wrap: wrap;
                  margin: 0 auto;
                  max-width: 1200px;
                }

                .pricing-card {
                  background: #fff;
                  border: 1px solid #e0e0e0;
                  border-radius: 10px;
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                  padding: 20px;
                  width: calc(33.333% - 20px);
                  margin: 10px;
                  text-align: center;
                  transition: transform 0.3s ease, box-shadow 0.3s ease;
                }

                .pricing-card:hover {
                  transform: translateY(-10px);
                  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
                }

                .pricing-card h3 {
                  font-size: 24px;
                  line-height: 1.16667;
                  font-weight: 600;
                  letter-spacing: .009em;
                  margin-bottom: 10px;
                }

                .pricing-card p {
                  font-size: 20px;
                  line-height: 1.47059;
                  font-weight: 400;
                  letter-spacing: -.022em;
                  margin-bottom: 20px;
                }

                .pricing-card ul {
                  list-style-type: none;
                  padding: 0;
                  margin: 0 0 20px;
                }

                .pricing-card ul li {
                  margin-bottom: 10px;
                  font-size: 16px;
                }

                .pricing-card button {
                  background-color: #0071e3;
                  color: #fff;
                  border: none;
                  padding: 10px 20px;
                  font-size: 16px;
                  border-radius: 5px;
                  cursor: pointer;
                  transition: background-color 0.3s ease;
                }

                .pricing-card button:hover {
                  background-color: #005bb5;
                }

                @media (max-width: 768px) {
                  .pricing-card {
                    width: calc(100% - 20px);
                  }
                }

                .plan-card ul { 
                  color: #fff;
                  font-weight: bold;
                }

                .plan-card ul li {
                    margin-bottom: 0.5rem;
                    color: #f1f1f1;
                    font-size: 0.9rem;
                }

                .plan-description {
                 color: #fff;
                                
                    height: 7rem;
                    overflow: hidden;
                }

                .price {
                color: #f1f1f1;
                }

                .pricing-table p {
                  color: #f1f1f1;
                }

                .pricing-table button {
                  color: #00316f;
                  background-color: #fff; 
                  padding: 8px 16px;
                  text-align: center;
                  text-decoration: none;
                  font-size: 16px;
                  margin: 4px 2px;
                  cursor: pointer;
                  border-radius: 50px;
                  margin-top: 20px;
                  box-shadow: none;
                  position: relative;
              }
             .month {
                  font-size: 1.2rem;
                  color: #fff;
              }
              .glamour-wrapper {
                width: 100%
              }
              .message-messageText {
                position: relative;
                display: block; /* Makes the element a block-level element */
                overflow: hidden;
                width: 100%;
                font-family: 'Courier New', Courier, monospace;
                white-space: normal; /* Allows text to wrap */
              }
                .features-apps-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Adjust grid layout for smaller screens */
@media (max-width: 1200px) {
  .features-apps-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .features-apps-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .features-apps-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}


.tile {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.tile-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  padding: 25px !important;
  overflow-y: auto;
  max-height: 100%;
}

.patients-wrapper, .billing-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.patients-wrapper {
  justify-content: center;
  align-items: center;
}

.twobygrid {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.twoby-grid-item-icon {
  flex-shrink: 0;
  margin-right: 1rem;
}

.twoby-grid-item-text {
  flex-grow: 1;
}

.typography-design-headline-dark {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.009em;
  color: #000000;
  padding-top: .25rem;
  padding-bottom: .25rem;
  text-align: left;
  margin-bottom: 0.5rem;
}

.typography-label-alt-dark {
  font-size: 16px;
  line-height: 1.4;
  color: #000000;
}

@media (max-width: 768px) {
  .tile {
    grid-column: span 6;
    height: auto;
    min-height: 700px;
  }

  .tile-content {
    padding: 20px !important;
  }

  .typography-design-headline-dark {
    font-size: 24px;
  }

  .typography-label-alt-dark {
    font-size: 14px;
  }
}



.billing-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}


.twoby-grid-item-icon {
  flex-shrink: 0;
  margin-right: 1rem;
}

.twoby-grid-item-text {
  flex-grow: 1;
}

.typography-design-headline-dark {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.009em;
  color: #000000;
  padding-top: .25rem;
  padding-bottom: .25rem;
  text-align: left;
  margin-bottom: 0.5rem;
}

.typography-label-alt-dark {
  font-size: 16px;
  line-height: 1.4;
  color: #000000;
}

@media (max-width: 768px) {
  .twobygrid {
    flex-direction: column;
    align-items: center;
  }

  .twoby-grid-item-icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .twoby-grid-item-text {
    text-align: center;
  }

  .typography-design-headline-dark {
    font-size: 24px;
    text-align: center;
  }

  .typography-label-alt-dark {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

}

.tile-tools {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tile-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}

.customFormHeader {
  margin-bottom: 20px;
}

/* Adjust FormEditor container */
.formEditor {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Adjust FormEditor content */
.formEditor > div {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Adjust the form elements container */
.formEditor .form-elements-container {
  flex: 1;
  overflow-y: auto;
}

/* Adjust individual form elements */
.formEditor .form-element {
  margin-bottom: 10px;
}

.tile-tools {
  display: flex;
  flex-direction: column;
  height: auto; /* Change from 100% to auto */
  min-height: 100%; /* Ensure it takes at least full height of grid cell */
}

.tile-content {
  display: flex;
  flex-direction: column;
  height: auto; /* Change from 100% to auto */
  overflow: visible; /* Allow content to be visible */
  padding: 25px;
  height: 100%;
}

.intakeFormMargin {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
 margin-bottom: 20px;
}

.customFormHeader {
  margin-bottom: 20px;
}

/* Adjust FormEditor container */
.formEditor {
  width: 100%; /* Ensure full width */
  overflow: visible; /* Allow content to be visible */
}

/* Adjust FormEditor content */
.formEditor > div {
  width: 100%; /* Ensure full width */
}

/* Adjust the form elements container */
.formEditor .form-elements-container {
  width: 100%; /* Ensure full width */
}

/* Adjust individual form elements */
.formEditor .form-element {
  margin-bottom: 10px;
  width: 100%; /* Ensure full width */
}

.demo-wrapper {
margin-top: 20px;
margin-left: auto;
margin-right: auto;
}

            `}</style>
    </div>
  );
};

export default LandingPage;
