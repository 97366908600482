import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import useUID from '../../General/useUID';
import { db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";

function StepTwelve({ onNext, prevStep, setCanProceed }) {
  const sigCanvasRef = useRef(null);
  const [uid] = useUID();
  const todayDate = new Date().toISOString().split('T')[0];
  const [isSigned, setIsSigned] = useState(false);

  useEffect(() => {
    // Update canProceed based on isSigned state
    setCanProceed(isSigned);
  }, [isSigned, setCanProceed]);

  const clearSignature = () => {
    sigCanvasRef.current.clear();
    setIsSigned(false); // Reset isSigned to false when the signature is cleared
  };

  const handleEnd = async () => {
    if (!sigCanvasRef.current.isEmpty()) {
      const signatureImage = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      // Save the signature to Firestore
      try {
        await setDoc(doc(db, `users/${uid}/applications/ipaApplication`), {
          signature: {
            image: signatureImage,
            date: todayDate
          }
        }, { merge: true }); // Use merge to update the document without overwriting other fields

        setIsSigned(true); // Mark as signed
      } catch (error) {
        console.error("Error saving signature:", error);
      }
    } else {
      setIsSigned(false); // Mark as unsigned if the canvas is empty
    }
  };

  return (
    <div className="affirmation-release">
      <h2>Affirmation and Release</h2>
      <p>I authorize IPA Popularis LLC and its related entities to exchange necessary medical and professional information. I release all parties from liability related to the release of such information and affirm the truthfulness of my provided information. False or incomplete information may result in termination.</p>
      
      <ul>
        <li>Authorization for information exchange with IPA Popularis.</li>
        <li>Release of liability for all involved in the information exchange.</li>
        <li>Affirmation of truthfulness and completeness of provided information.</li>
        <li>Warning that false or incomplete information may lead to termination.</li>
      </ul>
      <h4>Please Sign Below</h4>
      <SignatureCanvas
        penColor='black'
        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
        ref={sigCanvasRef}
        onEnd={handleEnd} // Automatically save the signature when the user stops drawing
      />
      <div>
        <button onClick={clearSignature}>Clear Signature</button>
      </div>
      <div className="input-field">
        <label>Date:</label>
        <input type="date" value={todayDate} readOnly />
      </div>
    </div>
  );
}

export default StepTwelve;
