import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function StepFive({ onNext, prevStep, setCanProceed  }) {
  const [uid] = useUID();
  const [educationEntries, setEducationEntries] = useState([]);

  const educationTypes = [
    { label: "Select Type", value: "" },
    { label: "Undergraduate", value: "Undergraduate" },
    { label: "Medical School", value: "MedicalSchool" },
    { label: "Internship", value: "Internship" },
    { label: "Residency", value: "Residency" },
    { label: "Fellowship", value: "Fellowship" },
    { label: "Board Certification", value: "Board Certification" },

    // Add more types as needed
  ];

    // Validation logic
    const isValidEntry = (entry) => {
      return entry.type && entry.name && entry.datesAttended; // Add more conditions as needed
    };
  
    useEffect(() => {
      // Check if all entries are valid
      const allEntriesValid = educationEntries.every(isValidEntry);
      setCanProceed(allEntriesValid);
    }, [educationEntries, setCanProceed]);

  useEffect(() => {
    const fetchEducationEntries = async () => {
      if (!uid) return;
      const educationRef = collection(db, `users/${uid}/applications/ipaApplication/education`);
      const querySnapshot = await getDocs(educationRef);
      const entries = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEducationEntries(entries);
    };
    
    fetchEducationEntries();
  }, [uid]);

  const addEducationEntry = async () => {
    const newEntry = {
      type: educationTypes[0].value, // Default to the first type
      name: '',
      degree: '',
      datesAttended: '',
      address: ''
    };
    
    const educationRef = collection(db, `users/${uid}/applications/ipaApplication/education`);
    const docRef = await addDoc(educationRef, newEntry);
    setEducationEntries([...educationEntries, { id: docRef.id, ...newEntry }]);
  };

  const handleEducationChange = async (index, field, value) => {
    const updatedEntries = [...educationEntries];
    const entryToUpdate = updatedEntries[index];
    entryToUpdate[field] = value;
    setEducationEntries(updatedEntries);

    const docRef = doc(db, `users/${uid}/applications/ipaApplication/education`, entryToUpdate.id);
    await updateDoc(docRef, { [field]: value });
  };

  // Function to delete an education entry
const deleteEducationEntry = async (index) => {
  const entryToDelete = educationEntries[index];
  if (!entryToDelete.id) return; // Exit if there's no Firestore ID (for safety)

  const docRef = doc(db, `users/${uid}/applications/ipaApplication/education`, entryToDelete.id);
  await deleteDoc(docRef); // Delete from Firestore

  const updatedEntries = [...educationEntries.slice(0, index), ...educationEntries.slice(index + 1)];
  setEducationEntries(updatedEntries); // Update local state
};


  return (
    <div>
      <h2>Education and Training</h2>
      {educationEntries.map((entry, index) => (
        <div key={entry.id || index} className="location-containers">
          <div className="input-group-row">
            <div className="input-field">
              <label>Type of Education:</label>
              <select
                value={entry.type}
                onChange={(e) => handleEducationChange(index, 'type', e.target.value)}
              >
                {educationTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label>Institution Name:</label>
              <input
                type="text"
                placeholder="Institution Name"
                value={entry.name}
                onChange={(e) => handleEducationChange(index, 'name', e.target.value)}
              />
            </div>

            <div className="input-field">
              <label>Degree:</label>
              <input
                type="text"
                placeholder="Degree"
                value={entry.degree}
                onChange={(e) => handleEducationChange(index, 'degree', e.target.value)}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label>Dates Attended:</label>
              <input
                type="text"
                placeholder="Dates Attended"
                value={entry.datesAttended}
                onChange={(e) => handleEducationChange(index, 'datesAttended', e.target.value)}
              />
            </div>
          </div>
          {index !== 0 && ( // Only show the delete button for non-primary locations
            <button onClick={() => deleteEducationEntry(index)} className="delete">
                     <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
        </div>
      ))}
      <button onClick={addEducationEntry} className="add-education-entry-button">Add New Education Entry</button>
    </div>
  );
}

export default StepFive;
