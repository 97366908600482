import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../subcomponents/Footer';
import Header from '../subcomponents/Header';
import PayerSelection from '../../Payers/PayerSelection';
import ICD10 from '../assets/diagnosis-codes.JPG';
import BillingCodes from '../assets/coding.JPG';
import { useNavigate } from "react-router-dom";

const TryClaims = () => {
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [isVisible, setIsVisible] = useState({});
    const sectionRefs = useRef({});
    const [email, setEmail] = useState(localStorage.getItem('email') || new URLSearchParams(location.search).get('email') || '');
    const navigate = useNavigate();

    const handlePayerSelect = (payer) => {
        setSelectedPayer(payer);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsVisible((prev) => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
                });
            },
            { threshold: 0.1, rootMargin: '-50px' }
        );

        Object.values(sectionRefs.current).forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        localStorage.setItem('email', email);
    }, [email]);

    const handleNavigation = (page) => {
        if (email) {
            if (!validateEmail(email)) {
                alert('Please enter a valid email address.');
            } else {
                navigate(`/${page}?email=${encodeURIComponent(email)}`);
            }
        } else {
            navigate(`/${page}`);
        }
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <div className="claims-page">
            <Helmet>
                <title>Claims | Popularis Health</title>
                <meta name="description" content="Streamline your healthcare billing with over 3,500 payers across the United States." />
                <meta name="keywords" content="Healthcare Billing, Clinic Management, Telehealth, Insurance Eligibility, medical AI, Patient management, patient electronic intake, Medical Software, Patient Eligibility, Batch Claims, AI in Healthcare" />
                <meta property="og:title" content="Claims | Popularis Health" />
                <meta property="og:description" content="Streamline your healthcare billing with over 3,500 payers across the United States." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
            </Helmet>
            <Header />
            <main className="claims-content">
                <section className="hero-section-sub-page" ref={el => sectionRefs.current['hero'] = el} id="hero">
                    <div className="golden-container">
                        <h1 className={`hero-title ${isVisible['hero'] ? 'animate' : ''}`}>Start Sending Claims in 5 Minutes.</h1>
                        <p className={`hero-subtitle ${isVisible['hero'] ? 'animate' : ''}`}>We make it easy to get paid by the complex Insurance Network.</p>
                    </div>
                </section>

                <section className="payer-search-section" id="get-started" ref={el => sectionRefs.current['payer-search'] = el}>
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['payer-search'] ? 'animate' : ''}`}>Find Your Payer</h2>
                        <p className={`section-description ${isVisible['payer-search'] ? 'animate' : ''}`}>We connect you to over 3,500 payers across the United States. Start by finding yours below.</p>
                        <div className={`payer-selector-wrapper ${isVisible['payer-search'] ? 'animate' : ''}`}>
                            <PayerSelection onSelect={handlePayerSelect} mode={'website'} />
                        </div>
                    </div>
                </section>

                <section className="process-section" ref={el => sectionRefs.current['process'] = el} id="process">
                    <div className="golden-container">
                        <h2 className={`section-title ${isVisible['process'] ? 'animate' : ''}`}>Our Streamlined Process</h2>
                        <div className="process-steps">
                            {[
                                { title: "Patient Information", description: "Easily input and manage patient data with our intuitive interface." },
                                { title: "Payer & Codes", description: "Select payers and enter appropriate codes with smart suggestions." },
                                { title: "Providers", description: "Link claims to the correct healthcare providers effortlessly." },
                                { title: "Submit", description: "Review and submit claims with confidence and ease." }
                            ].map((step, index) => (
                                <div className={`step ${isVisible['process'] ? 'animate' : ''}`} key={index} style={{animationDelay: `${index * 0.2}s`}}>
                                    <span className="step-number">{index + 1}</span>
                                    <h3 className="step-title">{step.title}</h3>
                                    <p className="step-description">{step.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="" ref={el => sectionRefs.current['sale'] = el} id="ai-notes">
                    <div className="golden-container Card">
                        <div
                            className={`tile-tools tile-dark pt-4 px-4`}
                            data-id="tools"
                            ref={el => sectionRefs.current['sale'] = el}
                        >
                            <h1 style={{ marginTop: "10px", marginBottom: "0px" }} className='typography-design-headline'>
                                Try Real-time Eligibility to instantly guarantee reimbursement.
                            </h1>
                            <p style={{  marginTop:"10px", marginBottom:"20px"  }} className="typography-label-alt-white">Batch billing, Batch eligibility, Instant Eligibility, Billable Encounters, Reports, we have it all!</p>
                            <div className={`cta-buttons`}>
                                <input 
                                    type="email" 
                                    placeholder="Email" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button style={{ marginTop:"0px", marginBottom:"0px" }} className="cta-button" onClick={() => handleNavigation('signup')}>
                                    Try for Free
                                </button>
                                <button style={{  marginTop:"0px", marginBottom:"0px"  }} className="cta-button" onClick={() => handleNavigation('demo')}>
                                    Book a Demo
                                </button>
                            </div>
                            <div style={{ paddingBottom: "10px" }} className="hero-disclaimer-container" >
                                <p style={{ textAlign:"left", marginBottom: "10px" }} className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>Immediate access - No credit card required</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="features-section" ref={el => sectionRefs.current['features'] = el} id="features">
                    <div className="golden-container">
                        <div className={`feature ${isVisible['features'] ? 'animate' : ''}`}>
                            <div className="feature-content">
                                <h2 className="feature-title">Intelligent Code Search</h2>
                                <p className="feature-description">Say goodbye to bulky code books. Our smart search makes finding the right ICD10 codes quick and easy.</p>
                                <ul className="feature-list">
                                    <li>Instant search results</li>
                                    <li>Frequently used codes at your fingertips</li>
                                    <li>AI-powered code suggestions</li>
                                </ul>
                            </div>
                            <div className="feature-image">
                                <img src={ICD10} alt="Intelligent ICD10 code search interface" className="feature-img" />
                            </div>
                        </div>

                        <div className={`feature reverse ${isVisible['features'] ? 'animate' : ''}`}>
                            <div className="feature-content">
                                <h2 className="feature-title">Billing Code Cards</h2>
                                <p className="feature-description">Create and reuse billing code cards for frequently submitted claims, saving time and reducing errors.</p>
                                <ul className="feature-list">
                                    <li>Customizable templates</li>
                                    <li>One-click claim population</li>
                                    <li>Secure storage of frequently used codes</li>
                                </ul>
                            </div>
                            <div className="feature-image">
                                <img src={BillingCodes} alt="Billing code card system for efficient claim submission" className="feature-img" />
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </div>
    );
};

export default TryClaims;