import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { doc, setDoc, getDoc } from "firebase/firestore";

function StepSix({ onNext, prevStep, setCanProceed }) {
  const [uid] = useUID();

  const [yesNoAnswers, setYesNoAnswers] = useState({
    useEMREHR: null,
    checkEligibility: null,
  });

  useEffect(() => {
    async function fetchData() {
      if (!uid) return;
      const infoRef = doc(db, `users/${uid}/applications/ipaApplication`);
      try {
        const docSnap = await getDoc(infoRef);
        if (docSnap.exists() && docSnap.data().AdminBillingInfo?.yesNoAnswers) {
          setYesNoAnswers(docSnap.data().AdminBillingInfo.yesNoAnswers);
        }
      } catch (error) {
        console.error("Error fetching admin and billing information:", error);
      }
    }

    fetchData();
  }, [uid]);

  useEffect(() => {
    // This effect runs whenever yesNoAnswers changes and saves the data automatically
    const saveDataAutomatically = async () => {
      if (!uid || yesNoAnswers.useEMREHR === null || yesNoAnswers.checkEligibility === null) return;
      const infoRef = doc(db, `users/${uid}/applications/ipaApplication`);
      try {
        await setDoc(infoRef, { AdminBillingInfo: { yesNoAnswers } }, { merge: true });
      } catch (error) {
        console.error("Error automatically saving admin and billing information:", error);
      }
    };

    saveDataAutomatically();
  }, [uid, yesNoAnswers]);

  useEffect(() => {
    const allAnswered = Object.values(yesNoAnswers).every(answer => answer !== null);
    setCanProceed(allAnswered);
  }, [yesNoAnswers, setCanProceed]);

  const handleYesNoChange = (question, answer) => {
    setYesNoAnswers(prevState => ({
      ...prevState,
      [question]: answer,
    }));
  };

  const questions = {
    useEMREHR: "Do you use an EMR/EHR at your office?",
    checkEligibility: "Do you check eligibility?",
  };

  return (
    <div>
      <h2>Administrative and Billing Information</h2>
      <div className="yes-no-questions-section">
        {Object.entries(questions).map(([questionKey, questionText]) => (
            <div key={questionKey} style={{ flexDirection: "column", display: "flex", marginBottom: "2rem" }}>
              <label>{questionText}</label>
                <label>
                  <input
                    type="radio"
                    className='custom-checkbox'
                    name={questionKey}
                    checked={yesNoAnswers[questionKey] === true}
                    onChange={(e) => handleYesNoChange(questionKey, true)}
                  /> Yes
                </label>
                <label>
                  <input
                    type="radio"
                    className='custom-checkbox'
                    name={questionKey}
                    checked={yesNoAnswers[questionKey] === false}
                    onChange={(e) => handleYesNoChange(questionKey, false)}
                  /> No
                </label>
              </div>
        ))}
      </div>
    </div>
  );
}

export default StepSix;
