import { useEffect, useState } from 'react';
import { db } from "../../../firebase"; 
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import useUID from '../../General/useUID';
import ProviderSelection from '../../Providers/ProviderSelection';

function StepTwo({ nextStep, setFormData, formData }) {
  const [uid] = useUID();
  // State to track the ID of the selected or existing primary provider
  const [selectedProviderIdexternal, setSelectedProviderIdexternal] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const validateSelection = () => {
    let errors = {};
  
    // Example validation: Ensure a provider is selected
    if (!selectedProviderIdexternal) {
      errors.provider = "A primary provider must be selected.";
    }
  
    setValidationErrors(errors);
  
    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  const handleNextStep = async () => {
    const isValid = validateSelection();
  
    if (isValid) {
      // Proceed to next step
      nextStep();
    } else {
      // Handle the invalid case, such as scrolling to the error or displaying a message
      console.error("Validation failed");
    }
  };
  
  

  useEffect(() => {
    // Function to fetch the existing primary provider
    const fetchPrimaryProvider = async () => {
      const primaryProviderRef = doc(db, "users", uid, "applications", "ipaApplication", "providers", "PrimaryProvider");
      const docSnap = await getDoc(primaryProviderRef);

      if (docSnap.exists()) {
        // Assuming providerId is stored in the document, update the selectedProviderId state
        setSelectedProviderIdexternal(docSnap.data().providerId);
      } else {
      }
    };

    if (uid) {
      fetchPrimaryProvider();
    }
  }, [uid]);

  const handleProviderSelection = async (data) => {

    Object.keys(data).forEach(key => providerData[key] === undefined && delete providerData[key]);

    const primaryProviderDocRef = doc(db, "users", uid, "applications", "ipaApplication", "providers", "PrimaryProvider");

    try {
      await setDoc(primaryProviderDocRef, providerData, { merge: true });
      // Update the selectedProviderId state with the current selection
      setSelectedProviderIdexternal(providerId);
    } catch (error) {
      console.error("Error adding/updating Primary Provider in Firestore:", error);
    }
  };

  return (
    <>
     <h2 style={{textAlign: "left"}}>Who's joining the club?</h2>
      <p style={{textAlign: "left"}} className='typography-label-alt'>Please select or add the Primary Provider joining the IPA.</p>
    <form className="provider-type-selection-form">     
          <div>
          <ProviderSelection
            onProviderSelect={handleProviderSelection}
            selectedProviderIdexternal={selectedProviderIdexternal}
          />
          {validationErrors.provider && (
            <p className="error-message">{validationErrors.provider}</p>
          )}
        </div>

    </form>
    </>
  );
}

export default StepTwo;
