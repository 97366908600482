import React, { useEffect, useRef, useState } from 'react';
import { Ball } from './physics';
import '@fortawesome/fontawesome-free/css/all.min.css';
import debounce from 'lodash.debounce';

const BALL_RADIUS = 50;
const ICONS = ['\uf0e7', '\uf005', '\uf118', '\uf0c3', '\uf121', '\uf135', '\uf188', '\uf0ac'];
const COLORS = [
    { color1: '#00316f', color2: '#0147df' },
    { color1: '#4b0082', color2: '#8a2be2' },
    { color1: '#006400', color2: '#32cd32' },
    { color1: '#8b0000', color2: '#ff4500' },
    { color1: '#191970', color2: '#4169e1' },
    { color1: '#556b2f', color2: '#6b8e23' },
    { color1: '#8b4513', color2: '#d2691e' },
    { color1: '#2f4f4f', color2: '#20b2aa' }
];

const calculateMaxBalls = (width) => {
    if (width < 768) return 0; // Hide on mobile
    if (width < 1024) return 5;
    if (width < 1440) return 6;
    if (width < 1920) return 7;
    return 8;
};

const preCalculateFrames = (numFrames, canvasWidth, canvasHeight, initialBalls, maxBalls) => {
    let balls = initialBalls.map((ball, index) => {
        const { color1, color2 } = COLORS[index % COLORS.length];
        return new Ball(ball.x, ball.y, ball.dx, ball.dy, ball.radius, color1, color2, ICONS[index % ICONS.length]);
    });
    const frames = [];

    for (let frame = 0; frame < numFrames; frame++) {
        balls = balls.slice(0, maxBalls);

        // Handle collisions every frame
        for (let i = 0; i < balls.length; i++) {
            for (let j = i + 1; j < balls.length; j++) {
                Ball.handleCollision(balls[i], balls[j]);
            }
        }

        // Add new balls faster but moderate
        if (balls.length < maxBalls && Math.random() < 0.05) {
            const x = Math.random() * canvasWidth;
            const y = -BALL_RADIUS - Math.random() * canvasHeight; // Start from higher
            const dx = (Math.random() - 0.5) * 2; // Moderate initial horizontal speed
            const dy = Math.random() * 1.5 + 1; // Moderate initial vertical speed
            const { color1, color2 } = COLORS[balls.length % COLORS.length];
            balls.push(new Ball(x, y, dx, dy, BALL_RADIUS, color1, color2, ICONS[balls.length % ICONS.length]));
        }

        balls.forEach((ball) => {
            ball.update(canvasWidth, canvasHeight);
            ball.dy += 0.1; // Moderate gravity

            // Bounce off bottom
            if (ball.y + ball.radius > canvasHeight) {
                ball.y = canvasHeight - ball.radius;
                ball.dy *= -0.7; // Bounciness
            }

            // Bounce off sides
            if (ball.x - ball.radius < 0 || ball.x + ball.radius > canvasWidth) {
                ball.dx *= -0.8; // Horizontal bounciness
            }
        });

        frames.push(balls.map((ball) => ({
            x: ball.x,
            y: ball.y,
            dx: ball.dx,
            dy: ball.dy,
            radius: ball.radius,
            color1: ball.color1,
            color2: ball.color2,
            icon: ball.icon,
            rotation: ball.rotation,
            shadow: {
                offsetY: ball.radius * 1.2,
                blur: ball.radius * 2 * (1 + (canvasHeight - ball.y) / canvasHeight),
                alpha: Math.max(0.05, 0.3 * (1 - Math.sqrt((canvasHeight - ball.y) / canvasHeight)))
            }
        })));
    }

    return frames;
};

const BouncingBall = () => {
    const canvasRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight * 0.7 });
    const [frames, setFrames] = useState([]);
    const [maxBalls, setMaxBalls] = useState(calculateMaxBalls(window.innerWidth));

    useEffect(() => {
        const handleResize = debounce(() => {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight * 0.7;
            setDimensions({ width: newWidth, height: newHeight });
            setMaxBalls(calculateMaxBalls(newWidth));
        }, 200);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (dimensions.width && dimensions.height && maxBalls > 0) {
            const initialBalls = [{ x: dimensions.width / 2.5, y: dimensions.height / 10, dx: 1, dy: 2, radius: BALL_RADIUS }];
            const numFrames = 1000; // Adjusting to ensure 10 seconds of animation

            const calculatedFrames = preCalculateFrames(numFrames, dimensions.width, dimensions.height - 50, initialBalls, maxBalls);
            setFrames(calculatedFrames);
        } else {
            setFrames([]);
        }
    }, [dimensions, maxBalls]);

    useEffect(() => {
        if (frames.length === 0 || !canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let frameIndex = 0;
        let animationId;

        const drawBall = (ctx, ball, canvasHeight) => {
            const { shadow } = ball;

            // Draw shadow
            ctx.save();
            ctx.translate(ball.x, canvasHeight - shadow.offsetY);
            ctx.scale(1.2, 0.5);
            ctx.beginPath();
            ctx.arc(0, 0, ball.radius, 0, Math.PI * 2);
            const shadowGradient = ctx.createRadialGradient(0, 0, 0, 0, 0, ball.radius);
            shadowGradient.addColorStop(0, `rgba(0, 0, 0, ${shadow.alpha})`);
            shadowGradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
            ctx.fillStyle = shadowGradient;
            ctx.shadowColor = `rgba(0, 0, 0, ${shadow.alpha})`;
            ctx.shadowBlur = shadow.blur;
            ctx.fill();
            ctx.restore();

            // Draw ball
            const angleRad = (145 * Math.PI) / 180;

            ctx.save();
            ctx.translate(ball.x, ball.y);
            ctx.rotate(ball.rotation);

            const gradient = ctx.createLinearGradient(
                -ball.radius * Math.cos(angleRad),
                -ball.radius * Math.sin(angleRad),
                ball.radius * Math.cos(angleRad),
                ball.radius * Math.sin(angleRad)
            );
            gradient.addColorStop(0, ball.color1);
            gradient.addColorStop(1, ball.color2);

            ctx.beginPath();
            ctx.arc(0, 0, ball.radius, 0, Math.PI * 2);
            ctx.fillStyle = gradient;
            ctx.fill();

            ctx.font = `${ball.radius}px FontAwesome`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#fff';
            ctx.fillText(ball.icon, 0, 0);

            ctx.restore();
        };

        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const balls = frames[frameIndex];
            balls.forEach(ball => drawBall(ctx, ball, canvas.height));

            if (frameIndex < frames.length - 1) {
                frameIndex++;
                animationId = requestAnimationFrame(animate);
            } else {
                cancelAnimationFrame(animationId); // Ensure the animation stops after the last frame
            }
        };

        animate();

        return () => {
            cancelAnimationFrame(animationId);
        };
    }, [frames]);

    if (maxBalls === 0) {
        return null; // Hide on mobile
    }

    return (
        <div>
            <canvas 
                ref={canvasRef} 
                width={dimensions.width} 
                height={dimensions.height} 
                style={{ display: 'block', margin: '0 auto' }}
                aria-label="Animated raining icons with physics simulation"
            />
            <noscript>
                <p>This animation shows colorful balls with various icons raining from above, bouncing off each other and the bottom of the screen.</p>
            </noscript>
        </div>
    );
};

export default BouncingBall;
