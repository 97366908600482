import { useState, useEffect, useMemo, useRef } from "react";
import { doc, deleteDoc, setDoc, getDoc, collection, getDocs } from "firebase/firestore";
import { unparse } from 'papaparse';
import style from '../PatientView.module.css';
import { auth, db } from "../../../firebase.js";
import { getFunctions, httpsCallable } from 'firebase/functions';
import styles from "../PatientFiles.modules.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import useUID from '../../General/useUID.jsx'
import mapPatientDataForDownload from './DownloadPatientFileMapping.js';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import HelpArticleLink from "../../Articles/HelpArticleLink.jsx";

const functions = getFunctions();

const capitalizeName = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};


const PatientDataDownload = ({ patientSelected }) => {
    const [uid, subUserUID] = useUID();
    const [isSubcollectionModalOpen, setIsSubcollectionModalOpen] = useState(false);
    const [isDownloadFormatModalOpen, setIsDownloadFormatModalOpen] = useState(false);
    const [selectedSubcollections, setSelectedSubcollections] = useState({
        encounters: true,
        notes: true,
        eligibility: true,
        claims: true,
        claimStatus: true,
        medicalHistory: true
    });

    const handleCheckboxChange = (event) => {
        setSelectedSubcollections({
            ...selectedSubcollections,
            [event.target.name]: event.target.checked
        });
    };

    const handleNextClick = () => {
        setIsSubcollectionModalOpen(false);
        setIsDownloadFormatModalOpen(true);
    };

    const handleBackClick = () => {
        setIsDownloadFormatModalOpen(false);
        setIsSubcollectionModalOpen(true);
    };

    const handleDownload = async (type) => {
        setIsDownloadFormatModalOpen(false);
        try {
            const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
            const patientDoc = await getDoc(patientRef);
    
            if (!patientDoc.exists()) {
                return;
            }
    
            // Fetch encrypted data
            const encryptedData = patientDoc.data().patient;
    
            const encryptedPaylad = {ciphertext: encryptedData.ciphertext, iv: encryptedData.iv }


            // Decrypt patient data
            const decryptFunction = httpsCallable(functions, 'decrypt');
            const decryptedResult = await decryptFunction(encryptedPaylad);
    
            if (!decryptedResult || !decryptedResult.data) {
                return;
            }
    
            let patientData = decryptedResult.data;

    
            // Fetch subcollections if needed
            const subcollectionsToFetch = Object.keys(selectedSubcollections).filter(key => selectedSubcollections[key]);
            for (const subcollection of subcollectionsToFetch) {
                patientData[subcollection] = await fetchCollection(patientRef, subcollection);
            }
    
            const flattenedData = mapPatientDataForDownload(patientData);
            const userData = await fetchUserData(uid);
            const patientBasicInfo = {
                fullName: `${capitalizeName(flattenedData.firstName)} ${capitalizeName(flattenedData.middleName)} ${capitalizeName(flattenedData.lastName)}`,
                firstName: `${capitalizeName(flattenedData.firstName)}`,
                middleName: `${capitalizeName(flattenedData.middleName)}`,
                lastName: `${capitalizeName(flattenedData.lastName)}`,
                memberId: flattenedData.memberId || '',
                insurance: flattenedData.insurance || '',
                secondaryMemberId: flattenedData.secondaryMemberId || '', // Add this line
                secondaryInsurance: flattenedData.secondaryInsurance || '', // Add this line
                dob: flattenedData.dob || '',
                gender: flattenedData.gender || '',
                phone: flattenedData.phone || '',
                email: flattenedData.email || '',
                address1: `${flattenedData.address1 || ''}`,
                address2: `${flattenedData.address2 || ''}`,
                city: `${flattenedData.city || ''}`,
                state: `${flattenedData.state || ''}`,
                zip: `${flattenedData.zip || ''}`,
                flag: flattenedData.flag || ''
            };            
    
            // Handle download based on the requested type
            if (type === 'xlsx') {
                handleDownloadExcel(flattenedData, patientBasicInfo, selectedSubcollections);
            } else if (type === 'pdf') {
                generatePDF(flattenedData, userData, patientBasicInfo,  selectedSubcollections);
            }
    
            // Log download activity
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            const ipAddress = data.ip;
            const addLogFunction = httpsCallable(functions, 'addLog');
            await addLogFunction({
                uid,
                message: `Individual Patient data downloaded. User IP address: ${ipAddress}.`
            });
    
        } catch (error) {
            console.error("Error in handleDownload:", error);
        }
    };
    

const decryptData = httpsCallable(functions, 'decrypt');

const fetchCollection = async (patientRef, collectionName) => {
    const collectionRef = collection(patientRef, collectionName);
    const snapshot = await getDocs(collectionRef);

    const decryptedDocs = await Promise.all(snapshot.docs.map(async (doc) => {
        const data = doc.data();

        // Check if the data needs decryption
        if (data.ciphertext && data.iv) {
            const encryptedPayload = { ciphertext: data.ciphertext, iv: data.iv };
            try {
                const decryptedResult = await decryptData(encryptedPayload);
                if (decryptedResult.data) {
                    // Check if the collection is medicalHistory
                    if (collectionName === 'medicalHistory') {
                        return {
                            ...decryptedResult.data,
                            timestamp: doc.data().timestamp?.toDate().toLocaleString(),
                            type: doc.data().type,
                        };
                    }
                    return decryptedResult.data;
                } else {
                    return {};
                }
            } catch (error) {
                console.error("Decryption error:", error);
                return {};
            }
        } else {
            // Directly handle medicalHistory collection without encryption
            if (collectionName === 'medicalHistory') {
                return {
                    ...data,
                    timestamp: doc.data().timestamp?.toDate().toLocaleString(),
                    type: doc.data().type,
                };
            }
            return data;
        }
    }));

    return decryptedDocs;
};



    const handleDownloadExcel = (flattenedData, patientInfo) => {
        const sanitizedFullName = patientInfo.fullName.replace(/[^a-zA-Z0-9]/g, '_');
        const sanitizedDOB = patientInfo.dob.replace(/[^a-zA-Z0-9]/g, '_');
        const filename = `${sanitizedFullName}_${sanitizedDOB}_PatientData.xlsx`;
        const workbook = XLSX.utils.book_new();
    
        // Create a sheet for patient's basic information
        const patientInfoSheet = XLSX.utils.json_to_sheet([patientInfo]); // Wrap in array to create one row
        XLSX.utils.book_append_sheet(workbook, patientInfoSheet, 'Patient_Info');
    
        // Add sheets for each subcollection
        Object.entries(flattenedData).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
                const flatObjectsArray = value.map(obj => {
                    // Flatten nested arrays for each object
                    return Object.fromEntries(
                        Object.entries(obj).flatMap(([k, v]) => 
                            Array.isArray(v) ? v.map((item, index) => [`${k}_${index+1}`, item]) : [[k, v]]
                        )
                    );
                });
    
                const ws = XLSX.utils.json_to_sheet(flatObjectsArray);
                XLSX.utils.book_append_sheet(workbook, ws, key);
            }
        });
    
        // Check if the workbook has any sheets added before trying to write it
        if (workbook.SheetNames.length > 0) {
            XLSX.writeFile(workbook, filename);
        } else {
            console.error('No data to write. The workbook is empty.');
        }
    };
    
    const generatePDF = async (patientData, userData) => {
        try {
            // Assuming patientBasicInfo contains fullName and dob properties
            const filename = `PatientReport.pdf`;
    
            const functions = getFunctions();
            const generatePDF = httpsCallable(functions, 'generatePDF');

            const result = await generatePDF({ uid, patientData, userData }); // Assuming userData is meant to be patientBasicInfo
            const { data } = result;
        
            // Decode the base64 string from the response
            const decodedData = atob(data.pdfBase64);
    
            // Convert the decoded string to an array of character codes
            const charCodes = decodedData.split('').map(c => c.charCodeAt(0));
    
            // Create a Blob from the character codes
            const blob = new Blob([new Uint8Array(charCodes)], { type: 'application/pdf' });
    
            // Create a URL for the Blob and trigger the download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename; // Use the dynamically created filename
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    
    
    const fetchUserData = async (uid) => {
        try {
            const userRef = doc(db, "users", uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                return userDoc.data();
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const formatLabel = (label) => {
        // Split the label into words where each capital letter indicates a new word.
        const words = label.match(/[A-Za-z][a-z]*/g) || [];
      
        // Capitalize the first letter of each word and join them with spaces.
        return words.map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
      };      

      const handleDownloadJson = async () => {
        setIsDownloadFormatModalOpen(false);
        try {
            const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
            const patientDoc = await getDoc(patientRef);
    
            if (!patientDoc.exists()) {
                return;
            }
    
            // Fetch encrypted data
            const encryptedData = patientDoc.data().patient;
            const encryptedPayload = { ciphertext: encryptedData.ciphertext, iv: encryptedData.iv };
    
            // Decrypt patient data
            const decryptFunction = httpsCallable(functions, 'decrypt');
            const decryptedResult = await decryptFunction(encryptedPayload);
    
            if (!decryptedResult || !decryptedResult.data) {
                return;
            }
    
            let patientData = decryptedResult.data;
    
            // Fetch subcollections if needed
            const subcollectionsToFetch = Object.keys(selectedSubcollections).filter(key => selectedSubcollections[key]);
            for (const subcollection of subcollectionsToFetch) {
                patientData[subcollection] = await fetchCollection(patientRef, subcollection);
            }
    
            // Prepare data for download
            const dataStr = JSON.stringify(patientData, null, 2);
            const blob = new Blob([dataStr], { type: 'application/json' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "PatientData.json";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        } catch (error) {
            console.error("Error in handleDownloadJson:", error);
        }
    };
    
    

    return (
        <>
            <span className='downloadText' onClick={() => setIsSubcollectionModalOpen(true)}>Download</span>
            
            {/* Modal for Subcollection Selection */}
            <Modal
                isOpen={isSubcollectionModalOpen}
                onRequestClose={() => setIsSubcollectionModalOpen(false)}
                className="confirmModal"
            >
            <button className='filesCloseButton' onClick={() => {setIsSubcollectionModalOpen(false)}}>X</button>
                <h2>Data Selection</h2>
                <h4>Please select the data you want downloaded.</h4>
                {Object.keys(selectedSubcollections).map(key => (
                    <div key={key}>
                        <input
                        type="checkbox"
                        className="custom-checkbox"
                        name={key}
                        checked={selectedSubcollections[key]}
                        onChange={handleCheckboxChange}
                        />
                        {formatLabel(key)} {/* Use the formatted label here */}
                    </div>
                    ))}
                <button onClick={handleNextClick}>Next</button>

                <HelpArticleLink 
                  article={{ 
                  title: 'Downloading Patient Data', 
                  link: 'https://popularishealth.com/article/Downloading-Patients-Data' 
                  }} 
                />
            </Modal>

            {/* Modal for Download Format Selection */}
            <Modal
                isOpen={isDownloadFormatModalOpen}
                onRequestClose={() => setIsDownloadFormatModalOpen(false)}
                className="confirmModal"
            >
                <button className='filesCloseButton' onClick={() => {setIsDownloadFormatModalOpen(false)}}>X</button>
                <h2>Download Format</h2>
                <h4>Please select the format you want the patient data in.</h4>
                <button onClick={() => handleDownload('xlsx')}>XLSX</button>
                <button onClick={() => handleDownload('pdf')}>PDF</button>
                <button onClick={handleDownloadJson}>JSON</button>
                <button onClick={handleBackClick}>Back</button>
                <HelpArticleLink 
                article={{ 
                title: 'Downloading Patient Data', 
                link: 'https://popularishealth.com/article/Downloading-Patients-Data' 
                }} 
                />
            </Modal>

        </>
    );
}

export default PatientDataDownload;