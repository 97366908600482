import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import posCodesWithIcons from '../components/POS';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { doc, getDocs, setDoc, collection, addDoc, updateDoc, deleteDoc } from "firebase/firestore";


const states = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];
const dayOptions = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
  { label: "Weekdays", value: "Weekdays" },
  { label: "Weekends", value: "Weekends" },
];
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


const TimeInput = ({ value, onChange }) => (
  <input type="time" value={value} onChange={onChange} />
);

function StepThree({ onNext, prevStep, setCanProceed }) {
  const [uid] = useUID();
  const [locations, setLocations] = useState([
    // Ensure hoursOfService is initialized as an empty array
    { posType: '', step: 'selectPOS', address1: '', address2: '', city: '', state: '', zip: '', hoursOfService: [] }
  ]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [addingNewLocation, setAddingNewLocation] = useState(true);

  useEffect(() => {
    const checkCanProceed = locations.length > 0 && locations.every(location => location.posType && location.address1);
    setCanProceed(checkCanProceed);
  }, [locations, setCanProceed]);

  useEffect(() => {
    // Assuming setCanProceed is a function passed from the parent to control the "Next" button
    setCanProceed(isFormValid);
  }, [isFormValid, setCanProceed]);
 
  useEffect(() => {
    window.scrollTo(0, 0);
}, [addingNewLocation]);

  useEffect(() => {
    if (!uid) return;
    
    const fetchLocations = async () => {
      const locationsRef = collection(db, `users/${uid}/applications/ipaApplication/locations`);
      const querySnapshot = await getDocs(locationsRef);
  
      const fetchedLocations = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      if (fetchedLocations.length) {
        // If there are existing locations, set them and disable adding new locations
        setLocations(fetchedLocations);
        setAddingNewLocation(false);
      } else {
        // If there are no existing locations, initialize with an empty location and allow adding new locations
        setLocations([
          { posType: '', step: 'selectPOS', address1: '', address2: '', city: '', state: '', zip: '', hoursOfService: [] }
        ]);
        setAddingNewLocation(true);
      }
    };

    fetchLocations();
  }, [uid]);

 

  const handleLocationChange = (index, field, value) => {
    const updatedLocations = [...locations];
    updatedLocations[index] = { ...updatedLocations[index], [field]: value };
    setLocations(updatedLocations);
    saveLocationToFirestore(updatedLocations[index], index);
  };
  

  
  

  const handlePosTypeChange = async (index, code) => {
    const newLocations = [...locations];
    newLocations[index] = { ...newLocations[index], posType: code, step: 'enterAddress' };
    setLocations(newLocations);
    setAddingNewLocation(false); // Reset the flag as the POS has been selected
    
    // Save the location to Firestore
    await saveLocationToFirestore(newLocations[index], index);
  };  


const addLocation = () => {
  setAddingNewLocation(true); // Set the flag when adding a new location
  // Ensure hoursOfService is an empty array for each new location
  setLocations([...locations, { posType: '', step: 'selectPOS', address1: '', address2: '', city: '', state: '', zip: '', hoursOfService: [] }]);
};




const saveLocationToFirestore = async (location, index) => {
  const locationRef = doc(db, `users/${uid}/applications/ipaApplication/locations`, `location${index}`);
  await setDoc(locationRef, location);
};

const saveHoursToFirestore = async (locationIndex, hours) => {
  const locationRef = doc(db, `users/${uid}/applications/ipaApplication/locations`, `location${locationIndex}`);
  await updateDoc(locationRef, {
    hoursOfService: hours
  });
};

const handleHoursChange = (locationIndex, dayIndex, field, value) => {
  const newLocations = [...locations];
  const newHours = { ...newLocations[locationIndex].hoursOfService[dayIndex], [field]: value };
  newLocations[locationIndex].hoursOfService[dayIndex] = newHours;
  setLocations(newLocations);
  saveHoursToFirestore(locationIndex, newLocations[locationIndex].hoursOfService);
};

const addHoursField = (index) => {
  const newLocations = [...locations];
  const newHoursOfService = [...newLocations[index].hoursOfService];
  newHoursOfService.push({ day: 'Monday', open: '09:00', close: '17:00' });
  newLocations[index] = { ...newLocations[index], hoursOfService: newHoursOfService };
  setLocations(newLocations);
  saveHoursToFirestore(index, newHoursOfService);
};

const removeHoursField = (locationIndex, hoursIndex) => {
  const updatedLocations = [...locations];
  updatedLocations[locationIndex].hoursOfService.splice(hoursIndex, 1);
  setLocations(updatedLocations);
  saveHoursToFirestore(locationIndex, updatedLocations[locationIndex].hoursOfService);
};

const handleDayChange = (locationIndex, hoursIndex, value) => {
  const updatedLocations = [...locations];
  updatedLocations[locationIndex].hoursOfService[hoursIndex].day = value;
  setLocations(updatedLocations);
  saveHoursToFirestore(locationIndex, updatedLocations[locationIndex].hoursOfService);
};

const deleteLocation = async (index) => {
  if(index === 0 && locations.length === 1) {
    alert("Cannot delete the primary location when it's the only location.");
    return;
  }
  
  let updatedLocations = [...locations];
  let locationToDelete = updatedLocations.splice(index, 1)[0]; // Remove the location from local state
  setLocations(updatedLocations);
  
  if (locationToDelete.id) {
    // If the location has an ID, delete it from Firestore
    const locationRef = doc(db, `users/${uid}/applications/ipaApplication/locations`, locationToDelete.id);
    await deleteDoc(locationRef);
  }
};




const posDescription = posType => posCodesWithIcons.find(pos => pos.code === posType)?.description || '';


return (
  <div className="provider-type-selection-form">
    <h2 style={{textAlign: "left"}}>Please list all of your locations.</h2>
    {!addingNewLocation && locations.map((location, index) => (
      <div className='location-containers' key={index}>
        {location.posType && (
          <FontAwesomeIcon 
            icon={posCodesWithIcons.find(pos => pos.code === location.posType)?.icon || ['fas', 'question-circle']} // Fallback icon if not found
            style={{ height: "40px" }}
          />
        )}
        <h3>Location {index + 1}: {location.posType && posDescription(location.posType)}</h3>
        <div>
            <h4>Hours of Service</h4>
            {location.hoursOfService?.map((hours, hoursIndex) => (
                <div key={hoursIndex} className="hours-service-row">
                  <select
                    value={hours.day}
                    onChange={(e) => handleDayChange(index, hoursIndex, e.target.value)}
                  >
                    {dayOptions.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  <TimeInput value={hours.open} onChange={(e) => handleHoursChange(index, hoursIndex, 'open', e.target.value)} />
                  <TimeInput value={hours.close} onChange={(e) => handleHoursChange(index, hoursIndex, 'close', e.target.value)} />
                  <button onClick={() => removeHoursField(index, hoursIndex)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ))}
          <button onClick={() => addHoursField(index)}>Add Hours</button>
        </div>
        {location.step === 'enterAddress' && (
          <div style={{textAlign: "left"}}>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor={`address1-${index}`}>Address Line 1:</label>
                <input 
                  id={`address1-${index}`} 
                  type="text" 
                  name="address1" 
                  value={location.address1} 
                  onChange={(e) => handleLocationChange(index, "address1", e.target.value)} 
                  />
              </div>
              <div className="input-field">
                <label htmlFor={`address2-${index}`}>Address Line 2 (optional):</label>
                <input 
                  id={`address2-${index}`} 
                  type="text" 
                  name="address2" 
                  value={location.address2} 
                  onChange={(e) => handleLocationChange(index, "address2", e.target.value)} 
                />
              </div>
            </div>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor={`city-${index}`}>City:</label>
                <input 
                  id={`city-${index}`} 
                  type="text" 
                  name="city" 
                  value={location.city} 
                  onChange={(e) => handleLocationChange(index, "city", e.target.value)} 
                />
              </div>
              <div className="input-field">
                <label htmlFor={`state-${index}`}>State:</label>
                <select 
                  id={`state-${index}`} 
                  name="state" 
                  value={location.state} 
                  onChange={(e) => handleLocationChange(index, "state", e.target.value)} 
                >
                  <option value="">Select a State</option>
                  {states.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
              </div>
              <div className="input-field">
                <label htmlFor={`zip-${index}`}>Zip:</label>
                <input 
                  id={`zip-${index}`} 
                  type="text" 
                  name="zip" 
                  maxLength={"5"}
                  pattern={"^\\d{5}$"}
                  value={location.zip} 
                  onChange={(e) => handleLocationChange(index, "zip", e.target.value)} 
                />
              </div>
            </div>
            {index !== 0 && ( // Only show the delete button for non-primary locations
                  <button onClick={() => deleteLocation(index)} className="delete">
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                  )}
          </div>
        )}
      </div>
    ))}
    {addingNewLocation && (
      <div >
        <h4>Select Place of Service for the New Location</h4>
        <div className="provider-type-cards-container">
          {posCodesWithIcons.map(({ code, icon, description }) => (
            <div key={code} className={`provider-card ${locations[locations.length - 1].posType === code ? 'selected' : ''}`} onClick={() => handlePosTypeChange(locations.length - 1, code)}>
              <FontAwesomeIcon icon={icon} style={{ height: "40px" }} />
              <h3>{description}</h3>
            </div>
          ))}
        </div>
      </div>
    )}
    <button onClick={() => {setAddingNewLocation(true); addLocation();}} disabled={addingNewLocation}>Add Another Location</button>
  </div>
);

}

export default StepThree;


