import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import HomePage from './components/General/Home/Homepage';
import './App.css';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import ForgotPassword from './components/Auth/ForgotPassword';
import Settings from './components/General/Account/settings';
import Logs from './components/General/Account/Logs';
import SignupStripe from './components/Auth/SignupStripe';
import LandingPage from './components/Website/pages/LandingPage';
import Claims from './components/Claims/Claims'
import PrivacyPolicyPage from './components/Website/pages/PrivacyPolicy';
import TermsOfUsePage from './components/Website/pages/Terms';
import SupportPage from './components/Website/pages/Support';
import ScrollToTop from './ScrollToTop'; 
import AI from './components/AI/AI'
import Teachingai from './components/AI/TeachingPage';
import SingleArticle from './components/Articles/SingleArticle';
import UploadArticle from './components/Articles/UploadArticle';
import Blog from './components/Articles/Blog';
import SearchResults from './components/Articles/SearchResults';
import ReactGA from "react-ga4";
import API from './components/General/API/API'
import useUID from './components/General/useUID';
import Users from './components/General/Users/Users';
import Activity from './components/PatientFiles/ActivityMenu';
import Messages from './components/General/Messages';
import PatientFiles from './components/PatientFiles/PatientFiles';
import AddPatientIntakeForm from './components/PatientFiles/Clinics/Intake/IntakeForm'
import TechSpecs from './components/Website/pages/Techspecs';
import Features from './components/Website/pages/Features';
import TryClaims from './components/Website/pages/Claims';
import TryPatients from './components/Website/pages/Patients';
import TryAI from './components/Website/pages/AI';
import Pricing from './components/Website/pages/Pricing';
import TryAPI from './components/Website/pages/API';
import IPA from './components/Website/pages/IPAPopularis';
import UserSupportPage from './components/General/UserSupport';
import IPAApplication from './components/IPA/IPAApplication';
import IPAPopularis from './components/IPA/IPAhome';
import VerifyEmailPage from './components/Auth/verifyEmail'
import PasswordReset from './components/Auth/PasswordReset';
import NotFoundPage from './components/General/NotFoundPage';
import AppointmentsCalendar from './components/PatientFiles/Clinics/AppointmentsCalendar';
import Demo from './components/Website/pages/Demo';
import GoogleAnalytics from './components/General/GoogleAnalytics';
import ApiQueueStatusPage from './components/General/API/ApiQueueStatusPage';

// import Encounters from "./components/PatientFiles/Encounters/Encounters";
// import PatientsRedirect from './components/PatientFiles/PatientsRedirect';


function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
      script.async = true;
      document.head.appendChild(script);
    }, []);


  // useEffect(() => {
  //   // Send pageview with a custom path
  //   ReactGA.send({ hitType: "pageview", page:  window.location.pathname + window.location.search, title: "Page Hit" });
  // }, [])

  const signInSuccess = () => {
    setIsSignedIn(true);
  };


  // function RestrictedRoute({ children }) {
  
  //   if (subUserUID) {
  //     // If subUserUID exists, redirect to home or some other default page
  //     return <Navigate to="/home" />;
  //   }
  
  //   return children;
  // }
  
  
  
  return (
    <Router>
      <ScrollToTop />
      <GoogleAnalytics />
      {isSignedIn ? (
        <Navigate to="/home" />
      ) : (
        <Routes>
          <Route path="/" element={< LandingPage />} />
          <Route path="/techspecs" element={< TechSpecs />} />
          <Route path="/pricing" element={< Pricing />} />
          <Route path="/tryclaims" element={< TryClaims />} />
          <Route path="/tryai" element={< TryAI />} />
          <Route path="/trypatients" element={< TryPatients />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/demo/:token?" element={< Demo />} />


          


          <Route path="/privacy" element={< PrivacyPolicyPage />} />
          <Route path="/terms" element={< TermsOfUsePage />} />
          <Route path="/support" element={< SupportPage />} />
          <Route path="/blog" element={< Blog />} />
          <Route path="/forgot-password" element={< ForgotPassword />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/signin/:nav?" element={<SignIn onSignInSuccess={signInSuccess} />} />
          <Route path="/signup/:token?" element={<SignUp />} />
          <Route path="/verify/:token?" element={<VerifyEmailPage />} />
          
          <Route path="/payment" element={<ProtectedRoute><SignupStripe /></ProtectedRoute>} />
          <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          <Route path="/claims/:claimIdfromURL?" element={<ProtectedRoute><Claims /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/ipapopularis" element={<ProtectedRoute><IPAPopularis /></ProtectedRoute>} />
          <Route path="/ipaapplication" element={<ProtectedRoute><IPAApplication /></ProtectedRoute>} />
          <Route path="/logs" element={<ProtectedRoute><Logs /></ProtectedRoute>} />
          <Route path="/api" element={<ProtectedRoute><API /></ProtectedRoute>} />
          <Route path="/apiqueue" element={<ProtectedRoute><ApiQueueStatusPage /></ProtectedRoute>} />
          <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
          <Route path="/activity" element={<ProtectedRoute><Activity /></ProtectedRoute>} />
          <Route path="/appointmentscalendar/:clinicIdURL?" element={<ProtectedRoute><AppointmentsCalendar /></ProtectedRoute>} />
          <Route path="/messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />
          <Route path="/ai" element={<ProtectedRoute><AI /></ProtectedRoute>} />
          <Route path="/usersupport" element={<ProtectedRoute><UserSupportPage /></ProtectedRoute>} />
          <Route path="/patients/:patientIdfromURL?" element={<ProtectedRoute><PatientFiles /></ProtectedRoute>} />
          <Route path="/patients/:PatientId/encounters" element={<ProtectedRoute><PatientFiles /></ProtectedRoute>} />
          <Route path="/intake/:clinic" element={<AddPatientIntakeForm />} />
          {/* <Route path="/1234dsasdfssdfjghjkgaerg123452134" element={<ProtectedRoute><Teachingai /></ProtectedRoute>} /> */}
          <Route path="/article/:id" element={<SingleArticle />} />
          <Route path="/articleadd" element={<ProtectedRoute><UploadArticle /></ProtectedRoute>} />
          <Route path="/search" element={<SearchResults />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
