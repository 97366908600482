import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { collection, addDoc, getDocs, updateDoc, doc } from "firebase/firestore";

function StepNine({ onNext, prevStep }) {
  const [uid] = useUID();
  const [claims, setClaims] = useState([]);
  const [hasClaims, setHasClaims] = useState([]);


  useEffect(() => {
    if (!uid) return;

    const fetchClaims = async () => {
      const colRef = collection(db, `users/${uid}/applications/ipaApplication/claims`);
      try {
        const querySnapshot = await getDocs(colRef);
        const claimsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClaims(claimsData);
      } catch (error) {
        console.error("Error fetching claims:", error);
      }
    };

    fetchClaims();
  }, [uid]);

  const handleClaimChange = async (index, field, value) => {
    let updatedClaims = [...claims];
    if (field.startsWith('court.')) {
      const courtField = field.split('.')[1];
      if (!updatedClaims[index].courtDetails) {
        updatedClaims[index].courtDetails = {};
      }
      updatedClaims[index].courtDetails[courtField] = value;
    } else {
      updatedClaims[index][field] = value;
    }

    setClaims(updatedClaims);

    // Update Firestore
    if (updatedClaims[index].id) {
      const claimRef = doc(db, `users/${uid}/applications/ipaApplication/claims`, updatedClaims[index].id);
      try {
        await updateDoc(claimRef, { [field.includes('court.') ? field.replace('court.', 'courtDetails.') : field]: value });
      } catch (error) {
        console.error("Error updating claim:", error);
      }
    }
  };

  const addClaim = async () => {
    const newClaim = {
      dateOfOccurrence: '',
      dateClaimsFiled: '',
      liabilityCarrier: '',
      patientName: '',
      claimantName: '',
      allegations: '',
      injuryDescription: '',
      otherDefendants: '',
      takenToCourt: false,
      courtDetails: {
        caseNumber: '',
        county: '',
        district: '',
      }
    };

    // Add to Firestore
    const colRef = collection(db, `users/${uid}/applications/ipaApplication/claims`);
    try {
      const docRef = await addDoc(colRef, newClaim);
      // Add the new claim to local state with Firestore document ID
      setClaims([...claims, { ...newClaim, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding new claim:", error);
    }
  };


  return (
    <div>
        <div className="form-question">
            <h2 >Sorry, but we have to ask...</h2>
          <p className='typography-label-alt'>Have you ever had a liability claim or lawsuit related to your practice of medicine?</p>
  
       </div>

      {claims.length === 0 && (
          <>
            <p style={{textAlign:"left"}} className='typography-label-alt'>Please list your claims.</p>
            <button onClick={addClaim} className="add-claim-button">Add a Claim</button>
          </>
      )}

        <>
          {claims.map((claim, index) => (
        <div key={index} className="location-containers">

            <div className="input-group-row">
                <div className="input-field">
                <label htmlFor="deaNumber">Date of Occurance:</label>
                    <input
                        type="date"
                        value={claim.dateOfOccurrence}
                        onChange={(e) => handleClaimChange(index, 'dateOfOccurrence', e.target.value)}
                    />
                    </div>

                <div className="input-field">
                <label htmlFor="deaNumber">Date Claims Were Filed:</label>
                <input
                    type="date"
                    value={claim.dateClaimsFiled}
                    onChange={(e) => handleClaimChange(index, 'dateClaimsFiled', e.target.value)}
                />
                </div>
            </div>

            <div className="input-group-row">
                <div className="input-field">
                <label htmlFor="deaNumber">Professional Liability Carrier Involved:</label>
                <input
                    type="text"
                    value={claim.liabilityCarrier}
                    onChange={(e) => handleClaimChange(index, 'liabilityCarrier', e.target.value)}
                />
                </div>

                <div className="input-field">
                <label htmlFor="deaNumber">Patient Name:</label>
                <input
                    type="text"
                    value={claim.patientName}
                    onChange={(e) => handleClaimChange(index, 'patientName', e.target.value)}
                />
                </div>

                <div className="input-field">
                <label htmlFor="deaNumber">Name of Claimant:</label>
                <input
                    type="text"
                    value={claim.claimantName}
                    onChange={(e) => handleClaimChange(index, 'claimantName', e.target.value)}
                />
                </div>
                </div>


                <textarea
                    value={claim.allegations}
                    onChange={(e) => handleClaimChange(index, 'allegations', e.target.value)}
                    placeholder="Describe allegations against you"
                />


                <textarea
                    value={claim.injuryDescription}
                    onChange={(e) => handleClaimChange(index, 'injuryDescription', e.target.value)}
                    placeholder="Describe alleged injury to patient"
                />


                <textarea
                    type="text"
                    value={claim.otherDefendants}
                    onChange={(e) => handleClaimChange(index, 'otherDefendants', e.target.value)}
                    placeholder="Identify all other defendants"
                />

                <div className="input-group-row">
                <label>
                    Was this taken to court?
                    <input
                    type="checkbox"
                    className='custom-checkbox'
                    checked={claim.takenToCourt}
                    onChange={(e) => handleClaimChange(index, 'takenToCourt', e.target.checked)}
                    />
                </label>
                </div>

                {claim.takenToCourt && (
                    <>

            <div className="input-group-row">
                <div className="input-field">
                <label htmlFor="deaNumber">Case Number:</label>
                    <input
                        type="text"
                        value={claim.courtDetails.caseNumber}
                        onChange={(e) => handleClaimChange(index, 'court.caseNumber', e.target.value)}
                    />
                </div>
                <div className="input-field">
                <label htmlFor="deaNumber">Location:</label>

                    <input
                        type="text"
                        value={claim.courtDetails.county}
                        onChange={(e) => handleClaimChange(index, 'court.county', e.target.value)}
                    />
                </div>

                <div className="input-field">
                <label htmlFor="deaNumber">District or Federal:</label>
                    <input
                        type="text"
                        value={claim.courtDetails.district}
                        onChange={(e) => handleClaimChange(index, 'court.district', e.target.value)}
                    />
                    </div>
                </div>
                </>

                )}
                <button onClick={() => addClaim()} className="add-claim-button">Add Another Claim</button>
                </div>
            ))}
                </>
            </div>
  );
}

export default StepNine;
