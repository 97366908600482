import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import * as XLSX from 'xlsx';
import './AddPatient.modules.css';
import { Timestamp } from 'firebase/firestore';
import PayerMatchingModal from '../../Claims/Configuration/PayerMatching';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, addDoc, updateDoc, doc, arrayUnion, setDoc, getDoc, query, orderBy, onSnapshot, getDocs } from "firebase/firestore";
import HexagonSpinner from '../../General/Animations/Hexspinner';
import Confetti from '../../General/Animations/Confetti';
import { getDatabase, ref as databaseRef, push, set } from 'firebase/database';

const functions = getFunctions();
import useUID from '../../General/useUID'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import HelpArticleLink from '../../Articles/HelpArticleLink';

function BatchUploadPopup({ onClose, selectedFolder, onDataUpdated }) {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const fileInputRef = useRef();
    const [rowErrors, setRowErrors] = useState([]);
    const [isPayerModalOpen, setIsPayerModalOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [genericErrors, setGenericErrors] = useState([]);
    const [uid, subUserUID] = useUID();
    const [eligibilityCheckModalVisible, setEligibilityCheckModalVisible] = useState(false);
    const [batchEligibilityCost, setBatchEligibilityCost] = useState(0);
    const [processedData, setProcessedData] = useState();
    const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
    const [unmatchedPayers, setUnmatchedPayers] = useState([]);
    const [showOverrideConfirm, setShowOverrideConfirm] = useState(false);
    const [jsonData, setJsonData] = useState([]);
    
    const handleDownload = () => {
        const message = document.getElementById('message');
        message.style.display = 'block';
        downloadXLSX();
    };

    const downloadXLSX = async () => {
        const templatePath = 'xlsxtemplates/batchpatients.xlsx';
        const storageRef = ref(storage, templatePath);
        const url = await getDownloadURL(storageRef);
        let link = document.createElement("a");
        link.href = url;
        link.download = "batchpatients.xlsx"; // Set the filename
        document.body.appendChild(link);
        link.click();
    };

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        console.log('File dropped');
        setIsProcessing(true);
        if (e.dataTransfer.items) {
            if (e.dataTransfer.items[0].kind === 'file') {
                const droppedFile = e.dataTransfer.items[0].getAsFile();
                handleFileChange(droppedFile);
            }
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFileChange = async (selectedFile) => {
        console.log('File selected:', selectedFile);
        setIsProcessing(true);
        setRowErrors([]);
        setGenericErrors([]);
        setMessage('');
        setIsPayerModalOpen(false);
        setEligibilityCheckModalVisible(false);
        setProcessedData(null);    
        setFile(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array', cellDates: true });
                    const wsname = workbook.SheetNames[0];
                    const ws = workbook.Sheets[wsname];
                    const parsedJsonData = XLSX.utils.sheet_to_json(ws);

                    parsedJsonData.forEach(entry => {
                        entry.PAYER = entry.PAYER ? entry.PAYER.trim().toUpperCase() : '';
                    });

                    setJsonData(parsedJsonData);

                    if (parsedJsonData.length > 1000) {
                        setGenericErrors(["The batch upload limit is 1,000 patients. Please reduce the number of patients and try again."]);
                        setIsProcessing(false);
                        return;
                    }

                    const numberOfPatients = parsedJsonData.length;
                    await fetchTierAndCalculateCost(numberOfPatients);

                    try {
                        const payerMatchesCollection = collection(db, `users/${uid}/payerMatches`);
                        const payerSnapshot = await getDocs(payerMatchesCollection);
                        const payerMap = payerSnapshot.docs.reduce((map, doc) => {
                            const payerData = doc.data();
                            map[payerData.name.trim().toUpperCase()] = payerData.payer;
                            return map;
                        }, {});

                        let hasPayer = false;
                        const unmatchedPayersSet = new Set();
                        parsedJsonData.forEach(entry => {
                            const payerName = entry.PAYER ? entry.PAYER.trim().toUpperCase() : undefined;
                            if (payerName && payerMap[payerName]) {
                                entry.RealtimePayerID = payerMap[payerName].RealtimePayerID || null;
                                entry.CPID = payerMap[payerName].CPID || null;
                                hasPayer = true;
                            } else if (payerName) {
                                unmatchedPayersSet.add(entry.PAYER);
                            }
                        });

                        const uniqueUnmatchedPayers = Array.from(unmatchedPayersSet);
                        setUnmatchedPayers(uniqueUnmatchedPayers);

                        const validationErrors = validateData(parsedJsonData, payerMap);

                        if (uniqueUnmatchedPayers.length > 0) {
                            setShowOverrideConfirm(true);
                        } else if (validationErrors.length > 0) {
                            setRowErrors(validationErrors);
                        } else if (hasPayer) {
                            setEligibilityCheckModalVisible(true);
                            setProcessedData(parsedJsonData);
                        } else {
                            await saveToFirestore(parsedJsonData, false);
                        }
                    } catch (error) {
                        console.error("Error processing file:", error);
                        setGenericErrors(["Error processing file. Please check the file and try again."]);
                    }
                    setIsProcessing(false);
                } catch (error) {
                    console.error("Error reading file:", error);
                    setGenericErrors(["Error reading file. Please check the file and try again."]);
                    setIsProcessing(false);
                }
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleOverrideConfirmation = (accept) => {
        setShowOverrideConfirm(false);
        if (accept) {
            setEligibilityCheckModalVisible(true);
            setProcessedData(jsonData);
        } else {
            setGenericErrors(["Batch upload canceled due to unmatched payers."]);
        }
    };

    const validateData = (data, payerMap) => {
        const errors = [];
        data.forEach((entry, index) => {
            let rowErrors = [];
            const isAddressProvided = ['address1', 'city', 'state', 'zip'].some(field => entry[field]);

            if (isAddressProvided) {
                if (!entry['address1']) rowErrors.push('Address1 is required.');
                if (!entry['city']) rowErrors.push('City is required.');
                if (!entry['state']) rowErrors.push('State is required.');
                if (!entry['zip']) rowErrors.push('Zip is required.');
            }

            ['firstName', 'lastName'].forEach(field => {
                if (!entry[field] || entry[field].trim() === '') {
                    rowErrors.push(`${field} is required and cannot be blank.`);
                }
            });

            if (entry['middleName'] && entry['middleName'].trim() === '') {
                rowErrors.push('Middle name cannot be blank or filled with only spaces.');
            }

            ['address1', 'address2', 'memberId', 'state', 'payer', 'zip'].forEach(field => {
                if (entry[field] && typeof entry[field] === 'string' && entry[field].trim() === '' && entry[field].length > 0) {
                    rowErrors.push(`${field} must not be blank or filled with only spaces.`);
                }
            });

            if (entry['gender'] && (entry['gender'] !== 'M' && entry['gender'] !== 'F')) {
                rowErrors.push('Gender must be "M" or "F".');
            }

            if (entry['dateOfBirth']) {
                const dob = formatDate(entry['dateOfBirth']);
                if (!dob) {
                    rowErrors.push('Date of birth must be in a valid format and not in the future.');
                } else {
                    entry['dateOfBirth'] = dob;
                }
            } else {
                rowErrors.push('Date of birth is required.');
            }

            if (entry['state'] && !/^[A-Za-z]{2}$/.test(entry['state'])) {
                rowErrors.push('State must be exactly two letters.');
            }

            if (entry['zip']) {
                let zip = String(entry['zip']).padStart(5, '0');
                if (!/^\d{5}$/.test(zip)) {
                    rowErrors.push('ZIP must be exactly five digits.');
                } else {
                    entry['zip'] = zip;
                }
            } else {
                rowErrors.push('ZIP is required.');
            }

            if (entry['PAYER'] && !payerMap.hasOwnProperty(entry['PAYER'].toUpperCase())) {
                rowErrors.push(`No payer match found for: ${entry['PAYER']}. Please use a matched payer.`);
            }

            if (rowErrors.length > 0) {
                errors.push({ row: index + 1, errors: rowErrors.join(', ') });
            }
        });

        return errors;
    };

    const formatDate = (input) => {
        if (input instanceof Date) {
            const day = input.getDate();
            const month = input.getMonth() + 1;
            const year = input.getFullYear();
            return `${('0' + month).slice(-2)}/${('0' + day).slice(-2)}/${year}`;
        }

        if (typeof input === 'string') {
            let match;

            if (match = input.match(/^(\d{2})\/(\d{2})\/(\d{4})$/)) {
                const [month, day, year] = match.slice(1).map(Number);
                const date = new Date(year, month - 1, day);
                if (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day) {
                    return date <= new Date() ? `${('0' + month).slice(-2)}/${('0' + day).slice(-2)}/${year}` : null;
                }
            }

            if (match = input.match(/^(\d{2})\/(\d{2})\/(\d{2})$/)) {
                let [month, day, year] = match.slice(1).map(Number);
                year += (year < 50 ? 2000 : 1900);
                const date = new Date(year, month - 1, day);
                if (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day) {
                    return date <= new Date() ? `${('0' + month).slice(-2)}/${('0' + day).slice(-2)}/${year}` : null;
                }
            }

            if (match = input.match(/^(\d{4})-(\d{2})-(\d{2})$/)) {
                const [year, month, day] = match.slice(1).map(Number);
                const date = new Date(year, month - 1, day);
                if (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day) {
                    return date <= new Date() ? `${('0' + month).slice(-2)}/${('0' + day).slice(-2)}/${year}` : null;
                }
            }
        } else if (typeof input === 'number') {
            const date = new Date((input - (25567 + 2)) * 86400 * 1000);
            if (date <= new Date()) {
                return `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()}`;
            }
        }
        return null;
    };

    const removeDuplicates = (data) => {
        const uniqueData = [];
        const lookup = new Set();

        data.forEach(entry => {
            const uniqueKey = `${entry.firstName.trim()}|${entry.lastName.trim()}|${entry.dateOfBirth}`;

            if (process.env.NODE_ENV === 'production') {
                if (!lookup.has(uniqueKey)) {
                    uniqueData.push(entry);
                    lookup.add(uniqueKey);
                }
            } else {
                uniqueData.push(entry);
                lookup.add(uniqueKey);
            }
        });

        return uniqueData;
    };

    const handleCancelEligibilityCheck = () => {
        setEligibilityCheckModalVisible(false);
        setIsProcessing(false);
    };

    const handleEligibilityCheck = async (runCheck, skipCheck = false) => {
        setEligibilityCheckModalVisible(false);
        setIsProcessing(true);

        if (skipCheck) {
            await saveToFirestore(processedData, false);
        } else {
            if (!hasRemainingRequests) {
                setMessage("You have reached the limit of 25 API requests for the free trial. Please upgrade to run more eligibility checks and send more claims.");
                setIsProcessing(false);
                return;
            }
            await saveToFirestore(processedData, runCheck);
        }
        setIsProcessing(false);
    };

    const saveToFirestore = async (data, runEligibilityCheckDecision) => {
        const processedData = data.map(entry => ({
            ...entry,
            PAYER: entry.PAYER ? entry.PAYER.toUpperCase() : entry.PAYER
        }));

        const deduplicatedData = removeDuplicates(processedData);

        try {
            const batchImportFunction = httpsCallable(functions, 'batchUploadToFirestore');
            const response = await batchImportFunction({ 
                jsonData: deduplicatedData,
                uid: uid,
                selectedFolder: selectedFolder,
                runEligibilityCheck: runEligibilityCheckDecision 
            });

            const logData = {
                timestamp: Date.now(),
                activity: 'batch patients upload',
                activityType: 'batchPatients',
                uid: uid
            };
        
            await addDoc(collection(db, "users", uid, "activityLogs"), logData);
            onDataUpdated()

            setMessage(response.data.message);
        } catch (error) {
            console.error("An error occurred during batch import:", error);
            setMessage('An error occurred during batch import. Please try again.');
        }
    };

    useEffect(() => {
        const fetchAccountTierAndCheckRemainingRequests = async () => {
            const uid = auth.currentUser.uid;
            const userDocRef = doc(db, "users", uid);
            const userDocSnap = await getDoc(userDocRef);
            const AccountTier = userDocSnap.data().AccountTier || '';
            const requestsRef = collection(db, "users", uid, "API");
            const q = query(requestsRef, orderBy("timestamp", "desc"));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let total = 0;
                querySnapshot.forEach((doc) => {
                    const requestData = doc.data();
                    if (requestData.APIRequests) {
                        const apiRequestsCount = parseInt(requestData.APIRequests, 10);
                        total += apiRequestsCount;
                    }
                });

                if (AccountTier === 'Freebie' && total >= 25) {
                    setHasRemainingRequests(false);
                } else {
                    setHasRemainingRequests(true);
                }
            });

            return () => unsubscribe();
        };

        if (auth.currentUser) {
            fetchAccountTierAndCheckRemainingRequests();
        }
    }, [auth.currentUser]);

    const fetchTierAndCalculateCost = async (numberOfPatients) => {
        const accountTierRef = doc(db, `users/${uid}`);
        const accountTierSnap = await getDoc(accountTierRef);
        let tierCharge = 0;
        if (accountTierSnap.exists()) {
            const accountTier = accountTierSnap.data().AccountTier;
            switch (accountTier) {
                case 'Professional':
                    tierCharge = 0.40;
                    break;
                case 'Enterprise':
                    tierCharge = 0.35;
                    break;
                case 'Freebie':
                default:
                    tierCharge = 0;
                    break;
            }
        }
        const cost = numberOfPatients * tierCharge;
        setBatchEligibilityCost(cost);
    };

    return (
        <div className="add-batch-popup">
            {isPayerModalOpen && <PayerMatchingModal mode={'all'} onClose={() => setIsPayerModalOpen(false)} />}
            <div className="batchContainer">
                <div className="upload-tools">
                    <div className="centerHeader">
                        <h3>Batch Patient Upload</h3>
                        <p className='subheader-container'>To upload batches use the Excel template. Ensure payers/insurance company's names are in our system by clicking "Payer Matching".</p>
                    </div>
                    <div className="stepsContainer">
                        <button id="templateButton" onClick={handleDownload}>Download XLSX Template</button>
                        <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
                        <button 
                            title="Match payers in your spreadsheet with those in Popularis."
                            onClick={() => setIsPayerModalOpen(true)}
                        >
                            Payer Matching for Template
                        </button>
                        <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
                        <input
                            type="file"
                            accept=".xlsx"
                            ref={fileInputRef}
                            onChange={(e) => handleFileChange(e.target.files[0])}
                            style={{ display: "none" }}
                        />
                        {isProcessing ? (
                            <HexagonSpinner />
                        ) : (
                            <div
                                className="dropbox"
                                onClick={handleFileInputClick}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                title="Upload files"
                                style={{ 
                                    display: "flex", 
                                    flexDirection: "column", 
                                    alignItems: "center", 
                                    justifyContent: "center" 
                                }}
                            >
                                <div className='dropBoxContent'>
                                    <FontAwesomeIcon icon={faCloudArrowUp} style={{ height: "3rem", marginBottom: ".5rem" }} />                
                                </div>
                                <p>Click to browse or drop files in here.</p>
                            </div>
                        )}
                    </div>
                    <div id="message" style={{ display: 'none' }}>Please fill out all fields. Do not modify headers.</div>
                    {selectedFolder && selectedFolder.name && selectedFolder.name !== "All Patients" && 
                        <p>Adding patients to {selectedFolder.name} clinic.</p>
                    }
                    <div className='genericErrors'>
                        {genericErrors.map((error, index) => (
                            <p key={index} className="errorText">{error}</p>
                        ))}
                    </div>
                    <p>{message}</p>
                </div>
                {rowErrors.length > 0 && (
                    <div className='errorsBatch'>
                        <p>{message}</p>
                        <table className="errorTable">
                            <thead>
                                <tr>
                                    <th>Row</th>
                                    <th>Errors</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowErrors.map((error, index) => (
                                    <tr key={index}>
                                        <td>{error.row}</td>
                                        <td>{error.errors}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <Modal
                isOpen={eligibilityCheckModalVisible}
                onRequestClose={handleCancelEligibilityCheck}
                className="confirmModal"
            >
                <button onClick={handleCancelEligibilityCheck} className='filesCloseButton'>X</button>
                <div className='modalContent'>
                    <h3>Do you want to run eligibility on these patients?</h3>
                    <p>It will cost ${batchEligibilityCost.toFixed(2)}. Confirming eligibility is recommended for ensuring payment by insurance.</p>
                    <div className='confirmButtons'>
                        <button className="primaryButton" onClick={() => handleEligibilityCheck(true)}>Run Batch Eligibility</button>
                        <button className="primaryButton" onClick={() => handleEligibilityCheck(false, true)}>Skip and Upload</button>
                    </div>
                </div>
            </Modal>
            <Modal className='confirmModal' isOpen={showOverrideConfirm} onRequestClose={() => setShowOverrideConfirm(false)}>
                <h2>Unmatched Payers Detected</h2>
                <p>Some payers were not matched:</p>
                <ul>
                    {unmatchedPayers.map((payer, index) => (
                        <li key={index}>{payer}</li>
                    ))}
                </ul>
                <p>Do you still want to continue?</p>
                <button onClick={() => handleOverrideConfirmation(true)}>Yes, Continue</button>
                <button onClick={() => handleOverrideConfirmation(false)}>No, Cancel</button>
            </Modal>

            <HelpArticleLink article={{ title: 'Adding Patients', link: 'https://popularishealth.com/article/Adding-Patients' }} />
        </div>
    );
}

export default BatchUploadPopup;
