import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical, faUser, faHeartPulse, faFileMedical, faStaffSnake, faClipboardList, faMagnifyingGlassDollar, faFileInvoiceDollar, faLock, faHeart, faCalendarAlt, faHospital } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

const BALL_RADIUS = 35;

const ICONS = [faUser, faNotesMedical, faHeartPulse, faFileMedical, faStaffSnake, faClipboardList, faCalendarAlt, faMagnifyingGlassDollar, faFileInvoiceDollar, faHospital];
const LABELS = ['Personal Info', 'Notes', 'Medical History', 'Files', 'Encounters', 'Intake Forms', 'Appointments', 'Eligibility', 'Claims', 'Clinics'];
const COLORS = [
    { color1: '#00316f', color2: '#0147df' },
    { color1: '#4b0082', color2: '#8a2be2' },
    { color1: '#006400', color2: '#32cd32' },
    { color1: '#8b0000', color2: '#ff4500' },
    { color1: '#191970', color2: '#4169e1' },
    { color1: '#556b2f', color2: '#6b8e23' },
    { color1: '#ff8c00', color2: '#ffa500' },
    { color1: '#8b4513', color2: '#d2691e' },
    { color1: '#dc143c', color2: '#ff69b4' } 
];

const createBall = (radius, color1, color2, icon) => ({
    radius,
    color1,
    color2,
    icon,
});

const BouncingBall = ({ ball }) => {
    const canvasRef = React.useRef(null);

    React.useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        const { radius, color1, color2 } = ball;

        canvas.width = radius * 2;
        canvas.height = radius * 2;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const gradient = ctx.createLinearGradient(-radius, -radius, radius, radius);
        gradient.addColorStop(0, color1);
        gradient.addColorStop(1, color2);

        ctx.beginPath();
        ctx.arc(radius, radius, radius, 0, Math.PI * 2);
        ctx.fillStyle = gradient;
        ctx.fill();
    }, [ball]);

    return (
        <div
            style={{
                position: 'relative',
                width: `auto`,
                height: `${ball.radius * 2}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '10px'
            }}
        >
            <canvas ref={canvasRef} style={{ position: 'absolute' }} />
            <FontAwesomeIcon icon={ball.icon} style={{ color: '#fff', height: '35px', zIndex: 1 }} />
        </div>
    );
};

const PatientsBalls = () => {
    const balls = LABELS.map((label, index) => {
        const { color1, color2 } = COLORS[index % COLORS.length];
        return createBall(BALL_RADIUS, color1, color2, ICONS[index % ICONS.length]);
    });

    return (
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <h2>All your Patients Data, Encrypted.</h2>
            <div className="patients-balls-grid">
                {balls.map((ball, index) => (
                    <div key={LABELS[index]} style={{ textAlign: 'center' }}>
                        <BouncingBall ball={ball} />
                        <span>{LABELS[index]}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PatientsBalls;
