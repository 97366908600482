import { auth, db } from "../../firebase";
import React, { useState, useEffect, useRef } from "react";
import styles from "./PatientFiles.modules.css";
import AddPatientPopup from "./AddPatient/AddPatientPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteField,
  collection,
  query,
  where,
  onSnapshot,
  writeBatch,
  orderBy,
  limit,
  startAfter,
  collectionGroup,
} from "firebase/firestore";
import {
  faTrash,
  faSortUp,
  faSortDown,
  faClockRotateLeft,
  faArrowUpWideShort,
  faSquareCheck,
  faArrowUp,
  faBan,
  faPhone,
  faClipboardList,
  faUserGraduate,
  faUserMd,
  faCheckDouble,
  faCircleExclamation,
  faPersonCirclePlus,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import BatchClaims from "./Billing/PatientsBatchClaims";
import useUID from "../General/useUID";
import { useNavigate } from "react-router-dom";
import HexagonSpinner from "../General/Animations/Hexspinner";
import DownloadBatchPatientXLSX from "./Download/DownloadBatchPatientXLSX";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";

function PatientList({
  onSelectPatient,
  selectedFolder,
  selectedPatient,
  patientIdfromURL,
  scrollingTableRef,
  DataUpdated,
}) {
  const ITEMS_PER_PAGE = 50;

  const [patients, setPatients] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [showCheckBoxes, setShowCheckBoxes] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSortFilterModalOpen, setIsSortFilterModalOpen] = useState(false);
  const [showBatchClaimsModal, setShowBatchClaimsModal] = useState(false);
  const [unbilledPatients, setUnbilledPatients] = useState(new Set());
  const [uid, subUserUID, error] = useUID();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showFlagDeleteConfirmModal, setShowFlagDeleteConfirmModal] =
    useState(false);
  const [flagToDelete, setFlagToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numPatientsToDisplay, setNumPatientsToDisplay] =
    useState(ITEMS_PER_PAGE);
  const [lastDocument, setLastPatient] = useState(null);
  const [hasMorePatients, setHasMorePatients] = useState(true);
  const [inViewport, setInViewport] = useState(false);
  const [flags, setFlags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedPatients, setDisplayedPatients] = useState("");
  const lastPatientRef = React.createRef();
  const initialLoad = useRef(true);
  const [searchResults, setSearchResults] = useState([]);
  const [sortResults, setSortResults] = useState([]);
  const [addPatientUpdated, setaddPatientUpdated] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // Last document for pagination
  const [hasMore, setHasMore] = useState(true); // If there's more data to fetch
  const [selectedFlags, setSelectedFlags] = useState({
    eligible: false,
    ineligible: false,
    deniedService: false,
    call: false,
    needsAssessment: false,
    enrolled: false,
    outpatient: false,
    intake: false,
  });
  const [applySortFilter, setApplySortFilter] = useState(false);

  const functions = getFunctions();
  const navigate = useNavigate();
  const inputRef = useRef(null); 
  const patientCache = useRef(new Map());

  const handleScroll = () => {
    if (scrollingTableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollingTableRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !isLoading &&
        hasMorePatients
      ) {
        fetchMorePatients();
      }
    }
  };

  const patientsToDisplay = searchTerm
    ? searchResults
    : sortResults.length > 0
    ? sortResults
    : patients;

  
  useEffect(() => {
    if (!uid) return;
    setPatients([]);
    setLastPatient(null);
    setHasMorePatients(true); // Reset pagination state if applicable

    // Reset searchTerm and sorted patients when folder changes
    setSearchTerm(""); // Reset search term
    setSortResults([]); // Clear sorted patients

    // Conditionally fetch data based on the selected folder
    if (uid && selectedFolder === "Deleted Patients") {
      fetchDataForDeleted();
    }
  }, [uid, selectedFolder]);

  useEffect(() => {
    setDisplayedPatients(patients.slice(0, numPatientsToDisplay));
  }, [patients, selectedFolder, numPatientsToDisplay]);

  
  useEffect(() => {
    if (!uid || !selectedFolder) {
      return;
    }
  
    setIsLoading(true);
    setPatients([]);
    setHasMore(true);
  
    let queryConstraints = [
      orderBy("__name__"),
      limit(ITEMS_PER_PAGE)
    ];
  
    // Conditionally set the 'deleted' field based on the selected folder
    if (selectedFolder === "Deleted Patients") {
      queryConstraints.push(where("deleted", "==", true));
    } else {
      queryConstraints.push(where("deleted", "==", false));
    }
  
    // Add condition specific to the clinic if `selectedFolder` is not "All Patients"
    if (selectedFolder.id) {
      queryConstraints.push(where("clinicToken", "==", selectedFolder.id));
    } else {
    }
  
    // Create the query to fetch patients
    const finalQuery = query(
      collection(db, "patients", uid, "patientData"),
      ...queryConstraints
    );
  
    const functions = getFunctions();
    const batchDecrypt = httpsCallable(functions, "batchDecryptPatients");
  
    const unsubscribe = onSnapshot(
      finalQuery,
      (querySnapshot) => {
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const encryptedPatients = querySnapshot.docs.map(doc => ({
          id: doc.id,
          encryptedData: doc.data().patient,
          flag: doc.data().flag,
          clinicToken: doc.data().clinicToken,
        }));
  
        batchDecrypt({
          patients: encryptedPatients.map((p) => p.encryptedData),
        })
        .then((result) => {
          const decryptedPatients = result.data.decryptedPatients.map(
            (decrypted, index) => ({
              id: encryptedPatients[index].id,
              data: decrypted,
              flag: encryptedPatients[index].flag,
              clinicToken: encryptedPatients[index].clinicToken,
            })
          );
  
          setPatients(decryptedPatients);
          setLastPatient(lastVisible); // Update the last patient
          setHasMorePatients(querySnapshot.docs.length === ITEMS_PER_PAGE);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to decrypt data:", error);
          setIsLoading(false);
        });
      },
      (error) => {
        console.error("Error fetching patients from Firestore:", error);
        setIsLoading(false);
      }
    );
  
    return () => {
      unsubscribe();
    };
  }, [uid, selectedFolder, DataUpdated]); // Keep DataUpdated if you need the list to refresh when data changes
  

  
  const fetchMorePatients = async () => {
    if (!hasMorePatients || isLoading || !uid) return;

    setIsLoading(true);

    let newQueryConstraints = [orderBy("__name__"), limit(ITEMS_PER_PAGE), where("deleted", "==", false)];
    if (selectedFolder !== "All Patients" && selectedFolder.id) {
      newQueryConstraints.unshift(
        where("clinicToken", "==", selectedFolder.id)
      );
    }

    if (lastDocument) {
      newQueryConstraints.push(startAfter(lastDocument));
    }

    const nextQuery = query(
      collection(db, "patients", uid, "patientData"),
      ...newQueryConstraints
    );

    const unsubscribe = onSnapshot(
      nextQuery,
      (querySnapshot) => {
        const newLastPatient = querySnapshot.docs[querySnapshot.docs.length - 1];
        const encryptedPatients = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          encryptedData: doc.data().patient,
          clinicToken: doc.data().clinicToken,
          flag: doc.data().flag,
        }));
    
        const batchDecrypt = httpsCallable(getFunctions(), "batchDecryptPatients");
        batchDecrypt({
          patients: encryptedPatients.map((p) => p.encryptedData),
        })
          .then((result) => {
            const decryptedPatients = result.data.decryptedPatients.map(
              (decrypted, index) => ({
                id: encryptedPatients[index].id,
                data: decrypted,
                clinicToken: encryptedPatients[index].clinicToken,
                flag: encryptedPatients[index].flag,
              })
            );
            setPatients((prev) => [...prev, ...decryptedPatients]);
            setLastPatient(newLastPatient); // Update the last patient
            setHasMorePatients(querySnapshot.docs.length === ITEMS_PER_PAGE);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Failed to decrypt data:", error);
            setIsLoading(false);
          });
      },
      (error) => {
        console.error("Error fetching more patients:", error);
        setIsLoading(false);
      }
    );
  
    return () => unsubscribe();
  };

  const fetchDataForDeleted = async () => {
    if (!uid) {
      return;
    }

    setIsLoading(true);

    try {
      const deletedPatientsQuery = query(
        collection(db, "patients", uid, "patientData"),
        where("deleted", "==", true),
        orderBy("__name__"),
        limit(ITEMS_PER_PAGE)
      );

      const querySnapshot = await getDocs(deletedPatientsQuery);
      const encryptedPatients = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        encryptedData: doc.data().patient,
      }));

      const batchDecrypt = httpsCallable(getFunctions(), "batchDecryptPatients");
      batchDecrypt({ patients: encryptedPatients.map((p) => p.encryptedData) })
        .then((result) => {
          const decryptedPatients = result.data.decryptedPatients.map(
            (decrypted, index) => ({
              id: encryptedPatients[index].id,
              data: decrypted,
              clinicToken: encryptedPatients[index].clinicToken,
              flag: encryptedPatients[index].flag,
            })
          );
          setPatients(decryptedPatients);
          setHasMorePatients(querySnapshot.docs.length === ITEMS_PER_PAGE);
        })
        .catch((error) => {
          console.error("Error decrypting deleted patients:", error);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const debounceTimeoutRef = useRef(null);

  const handleSearchInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
  
    debounceTimeoutRef.current = setTimeout(() => {
      fetchSearchResults(newSearchTerm);
    }, 300); // Adjust the debounce delay (in milliseconds) as needed
  };
  
  const normalizeDate = (input) => {
    // Regular expression to detect potential date formats with slashes
    const dateRegex = /(\d{1,2})\/(\d{1,2})(\/(\d{2,4}))?/;
  
    // Define the possible input formats
    const formats = [
      'MM/DD/YYYY',
      'M/D/YYYY',
      'MM/D/YYYY',
      'M/DD/YYYY',
      'MM/DD/YY',
      'M/D/YY',
      'MM/D/YY',
      'M/DD/YY',
      'YYYY-MM-DD',
      'YY-MM-DD',
    ];
    
    // Check if the input matches the date regex
    const match = input.match(dateRegex);
    if (match) {
      // If the year is not provided, use a partial date
      if (!match[3]) {
        const partialDate = `${match[1].padStart(2, '0')}/${match[2].padStart(2, '0')}`;
        return partialDate;
      }
  
      // If the year is provided, create a full date string
      const year = match[4].length === 2 ? `20${match[4]}` : match[4]; // Handle 2-digit year as 20xx
      const fullDateString = `${match[1]}/${match[2]}/${year}`;
  
      // Parse the full date string
      const parsed = moment(fullDateString, formats, true);
      if (parsed.isValid()) {
        const normalized = parsed.format('MM/DD/YYYY');
        return normalized;
      } else {
      }
    } else {
    }
  
    // If no match, or not a valid date, return the input as is
    return input;
  };
  
  
  const fetchSearchResults = async (searchTerm) => {
    if (!uid || !searchTerm.trim()) {
      setSearchResults([]); // Ensure search results are cleared if the search term is invalid
      setIsLoading(false);
      return;
    }
  
    // Normalize the search term early
    const normalizedSearchTerm = normalizeDate(searchTerm.trim());
  
    // Set loading state
    setIsLoading(true);
  
    try {
      let searchQueryConstraints = [orderBy("__name__")];
  
      if (selectedFolder === "Deleted Patients") {
        searchQueryConstraints.push(where("deleted", "==", true));
      } else {
        searchQueryConstraints.push(where("deleted", "==", false));
  
        if (selectedFolder && selectedFolder !== "All Patients" && selectedFolder.id) {
          searchQueryConstraints.push(where("clinicToken", "==", selectedFolder.id));
        }
      }
  
      const searchQuery = query(
        collection(db, `patients/${uid}/patientData`),
        ...searchQueryConstraints
      );
  
      const querySnapshot = await getDocs(searchQuery);
  
      const encryptedPatients = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        encryptedData: doc.data().patient,
      }));
  
      if (encryptedPatients.length === 0) {
        setSearchResults([]);
        setIsLoading(false);
        return;
      }
  
      // Prepare a list of patients that need decryption
      const patientsToDecrypt = encryptedPatients.filter(p => !patientCache.current.has(p.id));
  
      let decryptedPatients = [];
  
      if (patientsToDecrypt.length > 0) {
        console.log(`Decrypting ${patientsToDecrypt.length} patients...`);
        const functions = getFunctions();
        const batchDecrypt = httpsCallable(functions, "batchDecryptPatients");
        const result = await batchDecrypt({
          patients: patientsToDecrypt.map((p) => p.encryptedData),
        });
  
        if (result.data && result.data.decryptedPatients) {
          const trimData = (data) => {
            const trimField = (field) => (typeof field === 'string' ? field.trim() : field);
  
            return {
              ...data,
              firstName: trimField(data.patient.firstName),
              lastName: trimField(data.patient.lastName),
              phone: trimField(data.patient.phone),
              email: trimField(data.patient.email),
              address: {
                address1: trimField(data.patient.address?.address1),
                address2: trimField(data.patient.address?.address2),
                city: trimField(data.patient.address?.city),
                state: trimField(data.patient.address?.state),
                zip: trimField(data.patient.address?.zip),
              },
              dob: normalizeDate(trimField(data.patient.dob)), // Normalize the stored date
              payers: {
                name: trimField(data.payers.name),
                memberId: trimField(data.payers.memberId),
              },
            };
          };
  
          decryptedPatients = result.data.decryptedPatients.map((decrypted, index) => {
            const patient = {
              id: patientsToDecrypt[index].id,
              data: trimData(decrypted),
              clinicToken: patientsToDecrypt[index].clinicToken,
              flag: patientsToDecrypt[index].flag,
            };
            
            // Store in cache
            patientCache.current.set(patient.id, patient);
            console.log(`Decrypted and cached patient: ${patient.id}`);
            
            return patient;
          });
        }
      }
  
      // Combine cached patients with newly decrypted ones
      const cachedPatients = encryptedPatients.filter(p => patientCache.current.has(p.id)).map(p => {
        console.log(`Retrieved patient from cache: ${p.id}`);
        return patientCache.current.get(p.id);
      });
  
      decryptedPatients = [
        ...decryptedPatients,
        ...cachedPatients
      ];
  
      const lowerCaseSearchTerm = normalizedSearchTerm.toLowerCase();
  
      const filteredResults = decryptedPatients.map((patient) => {
        const searchableFields = [
          { key: 'firstName', value: patient.data.firstName },
          { key: 'lastName', value: patient.data.lastName },
          { key: 'phone', value: patient.data.phone },
          { key: 'email', value: patient.data.email },
          { key: 'address1', value: patient.data.address?.address1 },
          { key: 'address2', value: patient.data.address?.address2 },
          { key: 'city', value: patient.data.address?.city },
          { key: 'state', value: patient.data.address?.state },
          { key: 'zip', value: patient.data.address?.zip },
          { key: 'dob', value: normalizeDate(patient.data.dob) }, // Normalize date for comparison
          { key: 'payerName', value: patient.data.payers.name },
          { key: 'memberId', value: patient.data.payers.memberId },
        ];
  
        let matchedText = '';
        let matchedField = '';
  
        searchableFields.forEach((field) => {
          if (field.value && String(field.value).toLowerCase().includes(lowerCaseSearchTerm)) {
            matchedText = field.value;
            matchedField = field.key;
          }
        });
  
        return {
          ...patient,
          matchedText,
          matchedField,
        };
      }).filter(result => result.matchedText);
  
      setSearchResults(filteredResults);
      setHasMorePatients(querySnapshot.docs.length === ITEMS_PER_PAGE);
    } catch (firestoreError) {
      console.error("Error fetching search results from Firestore:", firestoreError);
      setSearchResults([]); // Clear search results on error
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (!uid || !selectedFolder) return;

    setIsLoading(true);
    setPatients([]);

    let queryConstraints = [orderBy("__name__"), limit(ITEMS_PER_PAGE)];

    if (selectedFolder === "Deleted Patients") {
      queryConstraints.push(where("deleted", "==", true));
    } else {
      queryConstraints.push(where("deleted", "==", false));

      if (selectedFolder !== "All Patients" && selectedFolder.id) {
        queryConstraints.push(where("clinicToken", "==", selectedFolder.id));
      }
    }

    const finalQuery = query(
      collection(db, `patients/${uid}/patientData`),
      ...queryConstraints
    );

    const unsubscribe = onSnapshot(
      finalQuery,
      async (querySnapshot) => {
        const encryptedPatients = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          encryptedData: doc.data().patient,
          flag: doc.data().flag,
          clinicToken: doc.data().clinicToken,
        }));

        const batchDecrypt = httpsCallable(functions, "batchDecryptPatients");
        const decryptionResults = await batchDecrypt({
          patients: encryptedPatients.map((p) => p.encryptedData),
        });

        const decryptedPatients = decryptionResults.data.decryptedPatients.map(
          (decrypted, index) => ({
            id: encryptedPatients[index].id,
            data: decrypted,
            flag: encryptedPatients[index].flag,
            clinicToken: encryptedPatients[index].clinicToken,
          })
        );

        // Filter patients based on selected flags
        const hasSelectedFlags = Object.values(selectedFlags).some(Boolean);
        const filteredPatients = hasSelectedFlags
          ? decryptedPatients.filter((patient) => selectedFlags[patient.flag?.toLowerCase() || "unknown"])
          : decryptedPatients;

        // Perform the sorting based on `sortBy` and `sortOrder`
        const sortedPatients = filteredPatients.sort((a, b) => {
          let aValue, bValue;

          switch (sortBy) {
            case "firstName":
              aValue = a.data.patient?.firstName?.toLowerCase() ?? '';
              bValue = b.data.patient?.firstName?.toLowerCase() ?? '';
              break;
            case "lastName":
              aValue = a.data.patient?.lastName?.toLowerCase() ?? '';
              bValue = b.data.patient?.lastName?.toLowerCase() ?? '';
              break;
            case "dob":
              aValue = new Date(a.data.patient.dob).getTime();
              bValue = new Date(b.data.patient.dob).getTime();
              break;
            case "flags":
              aValue = a.flag ? a.flag.toLowerCase() : '';
              bValue = b.flag ? b.flag.toLowerCase() : '';
              break;
            default:
              return 0; // Default case to avoid undefined behavior
          }

          if (sortOrder === "asc") {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
          } else {
            return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
          }
        });

        setPatients(sortedPatients);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching patients:", error);
        setIsLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [uid, selectedFolder, sortBy, sortOrder, selectedFlags, DataUpdated]);
  
  
  
  const handleApplySortFilter = () => {
    setApplySortFilter(true);
    setIsSortFilterModalOpen(false); // Close the modal after applying
  };

  useEffect(() => {
    if (!uid) return;
    const scrollingTable = scrollingTableRef.current;
    scrollingTable.addEventListener("scroll", handleScroll);

    return () => {
      // Detach the handleScroll function when the component unmounts
      scrollingTable.removeEventListener("scroll", handleScroll);
    };
  }, [uid, selectedFolder]);

  useEffect(() => {
    if (!uid) return;
    const selectPatientFromURL = async () => {

      // Wait until patients are fetched
      if (patients.length === 0) {
        setIsLoading(true);
        // Make sure you have a mechanism to fetch patients here if not already loaded.
        setIsLoading(false);
      }

      let patientToSelect = null;

      // If a patient ID is provided in the URL, try to find that patient
      if (patientIdfromURL) {
        patientToSelect = patients.find(
          (patient) => patient.id === patientIdfromURL
        );
      }

      // If a patient is found based on the URL or if no ID is provided, select the patient
      // Otherwise, fallback to the first patient in the list
      if (patientToSelect || !patientIdfromURL) {
        onSelectPatient(patientToSelect || patients[0]);
      } else if (patientIdfromURL && !patientToSelect) {
        // Optionally, fetch the patient's details if not found in the list and select it
        await fetchPatientData(patientIdfromURL);
      }
    };

    if (patients.length > 0 || patientIdfromURL) {
      selectPatientFromURL();
    }
  }, [patientIdfromURL, patients, uid]);

  useEffect(() => {
    if (!scrollingTableRef.current) return;
    const checkScrollTop = () => {
      const tableScrollPosition = scrollingTableRef.current.scrollTop;
      if (!showScrollToTop && tableScrollPosition > 400) {
        setShowScrollToTop(true);
      } else if (showScrollToTop && tableScrollPosition <= 400) {
        setShowScrollToTop(false);
      }
    };

    scrollingTableRef.current.addEventListener("scroll", checkScrollTop);

    return () => {
      if (scrollingTableRef.current) {
        scrollingTableRef.current.removeEventListener("scroll", checkScrollTop);
      }
    };
  }, [showScrollToTop]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.1, // 0.1 means 10% of the target element must be visible
    };

    const observer = new IntersectionObserver(([entry]) => {
      setInViewport(entry.isIntersecting);
    }, options);

    if (lastPatientRef.current) {
      observer.observe(lastPatientRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      if (lastPatientRef.current) {
        observer.unobserve(lastPatientRef.current);
      }
    };
  }, [lastPatientRef]);

  // Attach the scroll event listener
  useEffect(() => {
    if (scrollingTableRef.current) {
      scrollingTableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollingTableRef.current) {
        scrollingTableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const fetchPatientData = async (patientId) => {
    if (!uid) {
      return; // Exit the function if UID is not present
    }
  
    if (!patientId) {
      console.error("fetchPatientData: Missing patientId");
      return; // Exit the function if patientId is not present
    }
  
    try {
      const patientRef = doc(db, "patients", uid, "patientData", patientId);
      const patientSnapshot = await getDoc(patientRef);
  
      if (patientSnapshot.exists()) {
        const encryptedPatientData = patientSnapshot.data();
        const { iv, ciphertext } = encryptedPatientData.patient;
  
        // Check if iv and ciphertext are available
        if (!iv || !ciphertext) {
          console.error("fetchPatientData: Missing iv or ciphertext");
          return; // Exit the function if iv or ciphertext is missing
        }
  
        const encryptedPayload = {
          iv,
          ciphertext,
        };
  
        // Decrypt data
        const decryptFunction = httpsCallable(functions, "decrypt");
        const decryptedResult = await decryptFunction(encryptedPayload);
  
        if (decryptedResult.data) {
          const decryptedPatientData = decryptedResult.data;
  
          // After successful decryption, structure the patient data for further use
          const patient = {
            id: patientSnapshot.id,
            data: decryptedPatientData,
            clinicToken: encryptedPatientData.clinicToken,
          };
          onSelectPatient(patient);
        } else {
          console.error("Decryption failed, no data returned.");
        }
      } else {
        console.error("No patient found with ID:", patientId);
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };
  
  

  const handleDataUpdated = () => {
    setaddPatientUpdated(true); // This will trigger the useEffect
  };

  const handleSelectPatient = (patient) => {
    onSelectPatient(patient);
    if (patient && patient.id) {
      navigate(`/patients/${patient.id}`);
    }
  };

  const toggleCheckBoxes = () => {
    if (!isLoading) {
      setShowCheckBoxes((prev) => !prev);
      setSelectedPatients([]);
    }
  };

  const togglePatientSelection = (id) => {
    setSelectedPatients((prevState) =>
      prevState.includes(id)
        ? prevState.filter((patientId) => patientId !== id)
        : [...prevState, id]
    );
  };

  const handleDeleteSelectedPatients = async () => {
    const batch = writeBatch(db);
    for (let patientId of selectedPatients) {
      const patientDocRef = doc(db, "patients", uid, "patientData", patientId);
      batch.update(patientDocRef, { deleted: true });
    }
    await batch.commit();
    setPatients((prevPatients) =>
      prevPatients.filter((patient) => !selectedPatients.includes(patient.id))
    );
    setSelectedPatients([]);
  };

  const requestDeleteSelectedPatients = () => {
    if (selectedPatients.length > 0) {
      setShowConfirmModal(true);
    }
  };

  const confirmDelete = () => {
    handleDeleteSelectedPatients();
    setShowConfirmModal(false);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  function highlightMatch(text, searchTerm) {
    const safeText = text?.toString() ?? "";
    const safeSearchTerm = searchTerm ? searchTerm.toString() : "";
  
    if (!safeSearchTerm) return safeText;
  
    const index = safeText.toLowerCase().indexOf(safeSearchTerm.toLowerCase());
    if (index === -1) return safeText;
  
    const beforeMatch = safeText.substring(0, index);
    const match = safeText.substring(index, index + safeSearchTerm.length);
    const afterMatch = safeText.substring(index + safeSearchTerm.length);
  
    return (
      <>
        {beforeMatch}
        <strong>{match}</strong>
        {afterMatch}
      </>
    );
  }
  

  function capitalizeName(name) {
    if (!name) {
      return "";
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const handleSendClaims = () => {
    if (selectedPatients.length > 0) {
      setShowBatchClaimsModal(true);
    }
  };

  const selectAllPatients = async () => {
    if (isLoading) return; // Prevent action if already loading
  
    setIsLoading(true); // Set loading to true
    const allPatientIds = [];

    const fetchAllPatients = async (lastDoc = null) => {
      let queryConstraints = [orderBy("__name__"), limit(ITEMS_PER_PAGE)];
  
      if (selectedFolder === "Deleted Patients") {
        queryConstraints.push(where("deleted", "==", true));
      } else {
        queryConstraints.push(where("deleted", "==", false));
      }
  
      if (selectedFolder !== "All Patients" && selectedFolder.id) {
        queryConstraints.push(where("clinicToken", "==", selectedFolder.id));
      }
  
      if (lastDoc) {
        queryConstraints.push(startAfter(lastDoc));
      }
  
      const finalQuery = query(
        collection(db, "patients", uid, "patientData"),
        ...queryConstraints
      );
  
      const querySnapshot = await getDocs(finalQuery);
  
      if (querySnapshot.empty) return;
  
      const batchDecrypt = httpsCallable(functions, "batchDecryptPatients");
      const encryptedPatients = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        encryptedData: doc.data().patient,
        flag: doc.data().flag,
        clinicToken: doc.data().clinicToken,
      }));
  
      const result = await batchDecrypt({
        patients: encryptedPatients.map((p) => p.encryptedData),
      });
  
      const decryptedPatients = result.data.decryptedPatients.map(
        (decrypted, index) => ({
          id: encryptedPatients[index].id,
          data: decrypted,
          flag: encryptedPatients[index].flag,
          clinicToken: encryptedPatients[index].clinicToken,
        })
      );
  
      allPatientIds.push(...decryptedPatients.map((patient) => patient.id));
  
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  
      if (querySnapshot.docs.length === ITEMS_PER_PAGE) {
        await fetchAllPatients(lastVisible);
      }
    };
  
    await fetchAllPatients();
    setSelectedPatients(allPatientIds);
    setIsLoading(false); // Reset loading state when done
  };
  

  const scrollToTop = () => {
    if (scrollingTableRef.current) {
      scrollingTableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const getFlagIcon = (flag) => {
    if (!flag) return { icon: null, tooltip: "", className: "" };
  
    switch (flag) {
      case "Eligible":
        return {
          icon: faCheckDouble,
          tooltip: "Eligible",
          className: styles.Eligible,
        };
      case "Ineligible":
        return {
          icon: faCircleExclamation,
          tooltip: "Ineligible",
          className: styles.Ineligible,
        };
      case "Denied Service":
        return {
          icon: faBan,
          tooltip: "Denied Service",
          className: styles.deniedService,
        };
      case "Call":
        return { icon: faPhone, tooltip: "Call", className: styles.call };
      case "Needs Assessment":
        return {
          icon: faClipboardList,
          tooltip: "Needs Assessment",
          className: styles.needsAssessment,
        };
      case "Enrolled":
        return {
          icon: faUserGraduate,
          tooltip: "Enrolled",
          className: styles.enrolled,
        }; // Add case for Enrolled
      case "Outpatient":
        return {
          icon: faUserMd,
          tooltip: "Outpatient",
          className: styles.outpatient,
        }; // Add case for Outpatient
      case "intake":
        return {
          icon: faPersonCirclePlus,
          tooltip: "Patient added from intake",
          className: styles.intake
        }
      default:
        return { icon: null, tooltip: "", className: "" };
    }
  };
  


  const requestRemovePatientFlag = (patientId, flag) => {
    setFlagToDelete({ patientId, flag });
    setShowFlagDeleteConfirmModal(true);
  };

  const confirmFlagDelete = async () => {
    if (flagToDelete) {
      const patientRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        flagToDelete.patientId
      );
      await updateDoc(patientRef, {
        flag: deleteField(), // Directly deleting the 'flag' field
      });

      setFlags((prevFlags) => {
        const newFlags = { ...prevFlags };
        delete newFlags[flagToDelete.patientId]; // Removing the flag from the local state
        return newFlags;
      });

      // Reset the state after deletion
      setFlagToDelete(null);
      setShowFlagDeleteConfirmModal(false);
    }
  };

  const recoverSelectedPatients = async () => {
    const batch = writeBatch(db);

    // Determine which patients to recover based on whether "Select All" is active
    const patientsToRecover =
      selectedPatients.length > 0
        ? selectedPatients
        : patients
            .filter((patient) => patient.data.deleted)
            .map((patient) => patient.id);

      patientsToRecover.forEach((patientId) => {
        const patientRef = doc(db, "patients", uid, "patientData", patientId);
        batch.update(patientRef, { deleted: false });
      });

    try {
      await batch.commit();
      setSelectedPatients([]);
    } catch (error) {
      console.error("Error recovering patients:", error);
    }
  };
  

  const handleFlagChange = (flag) => {
    setSelectedFlags((prev) => ({
      ...prev,
      [flag]: !prev[flag],
    }));
  };

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (document.activeElement !== inputRef.current) {
  //       inputRef.current.focus();
  //     }
  //   };
  
  //   window.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  return (
    <>
      <div className="claimsContainer">
        {showBatchClaimsModal && (
          <BatchClaims
            selectedPatientsData={selectedPatients.map((id) =>
              patients.find((patient) => patient.id === id)
            )}
            onClose={() => setShowBatchClaimsModal(false)}
          />
        )}
      </div>
      <div className={"patientFiles"}>
        <div className={styles.controls}>
          <AddPatientPopup
            selectedFolder={selectedFolder}
            onDataUpdated={handleDataUpdated}
          />
            <input
            className="search-input-patients"
            type="text"
            placeholder="Search all patients"
            value={searchTerm}
            onChange={handleSearchInputChange}
            ref={inputRef} 
            style={{ fontSize: '16px' }} 
          />
          <div className="patientControls">
            {showCheckBoxes && (
              <button
                className="addpatientbatchButton"
                onClick={selectAllPatients}
                disabled={isLoading} // Disable button when loading
              >
                <FontAwesomeIcon title={"Select All"} icon={faCheckDouble} />
              </button>
            )}
            {selectedFolder === "Deleted Patients" &&
              selectedPatients.length > 0 && (
                <button
                  className="addpatientbatchButton"
                  onClick={recoverSelectedPatients}
                  disabled={isLoading} // Disable button when loading
                >
                  Recover
                </button>
              )}

            <button
              className="addpatientbatchButton"
              onClick={toggleCheckBoxes}
              disabled={isLoading} // Disable button when loading
            >
              {showCheckBoxes ? (
                "Cancel"
              ) : (
                <FontAwesomeIcon
                  title={"Select Multiple"}
                  icon={faSquareCheck}
                />
              )}
            </button>
            <button
              className="addpatientbatchButton"
              onClick={() => setIsSortFilterModalOpen(true)}
              disabled={isLoading} // Disable button when loading
            >
              <FontAwesomeIcon
                title={"Filter Claims"}
                icon={faArrowUpWideShort}
              />
            </button>
            <button
              title="View recent activity"
              className="addpatientbatchButton"
              onClick={() => navigate("/activity")}
            >
              <FontAwesomeIcon icon={faClockRotateLeft} />
            </button>
            <button
              title="View Appointments Calendar"
              className="addpatientbatchButton"
              onClick={() => navigate("/appointmentscalendar")}
            >
              <FontAwesomeIcon icon={faCalendar} />
            </button>
          </div>
          <div className="multiControls">
            {selectedPatients.length > 0 && (
              <>
                {!subUserUID && (
                  <>
                    <button className="primary" onClick={handleSendClaims}>
                      Send Claims
                    </button>
                  </>
                )}
                <DownloadBatchPatientXLSX
                  selectedPatients={selectedPatients}
                  uid={uid}
                />
                <button
                  className="delete"
                  onClick={requestDeleteSelectedPatients}
                >
                  <FontAwesomeIcon
                    title={"Delete selected patients"}
                    icon={faTrash}
                  />
                </button>
              </>
            )}
          </div>
        </div>

        <div className="scrollingTable" ref={scrollingTableRef}>
          <table className={styles.scrollingTable}>
            <thead></thead>
            <tbody>
              {patientsToDisplay.map((patient, index) => (
                <tr
                  key={`${patient.id}-${index}`}
                  ref={
                    index === patientsToDisplay.length - 1
                      ? lastPatientRef
                      : null
                  }
                  onClick={() => handleSelectPatient(patient)}
                  className={
                    selectedPatient && patient.id === selectedPatient.id
                      ? "selected-patient"
                      : ""
                  }
                >
                  {showCheckBoxes && (
                    <td>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectedPatients.includes(patient.id)}
                          onChange={() => togglePatientSelection(patient.id)}
                        />
                        <span className="checkbox">
                          <span className="checkmark"></span>
                        </span>
                      </label>
                    </td>
                  )}
                  <td className="patientListName">
                    <div className="patient-name">
                      <span
                        title={`DOB: ${
                          patient.data.patient?.dob ?? "N/A"
                        }, Gender: ${patient.data.patient?.gender ?? "N/A"}`}
                      >
                        {highlightMatch(
                          capitalizeName(patient.data.patient?.firstName ?? ""),
                          searchTerm
                        )}{" "}
                        {highlightMatch(
                          capitalizeName(patient.data.patient?.lastName ?? ""),
                          searchTerm
                        )}
                      </span>
                    </div>
                    <span className="matchedSearchText">
                        {highlightMatch(patient.matchedText, searchTerm)}{" "}
                      </span>
                    <div className="patient-flag">
                        {patient.flag && (
                          <span
                            className={styles.patientFlagIndicator}
                            title={getFlagIcon(patient.flag).tooltip}
                          >
                            <FontAwesomeIcon
                              icon={getFlagIcon(patient.flag).icon}
                              onClick={() => requestRemovePatientFlag(patient.id, patient.flag)}
                              style={{ marginLeft: "2rem" }}
                            />
                          </span>
                        )}
                      </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="loadingContainer">
              <HexagonSpinner />
              <p className="authMessage">Decrypting...</p>
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={showConfirmModal}
        onRequestClose={cancelDelete}
        className="confirmModal"
      >
        <h2>Delete Selected Patients?</h2>
        <p>Are you sure you want to delete the selected patients?</p>
        <div className={styles.confirmButtons}>
          <button className="secondaryButton" onClick={cancelDelete}>
            Cancel
          </button>
          <button className="dangerButton" onClick={confirmDelete}>
            Delete
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showFlagDeleteConfirmModal}
        onRequestClose={() => setShowFlagDeleteConfirmModal(false)}
        className="confirmModal"
      >
        <h2>Delete the flag {flagToDelete?.flag}?</h2>
        <div className={styles.confirmButtons}>
          <button
            className="secondaryButton"
            onClick={() => setShowFlagDeleteConfirmModal(false)}
          >
            Cancel
          </button>
          <button className="dangerButton" onClick={confirmFlagDelete}>
            Delete Flag
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isSortFilterModalOpen}
        onRequestClose={() => setIsSortFilterModalOpen(false)}
        className="confirmModal"
        style={{ minHeight: '90vh' }}
      >
       <button className='filesCloseButton'onClick={() => setIsSortFilterModalOpen(false)}>X</button>
        <h2>Sort and Filter</h2>
        <div className="grid-container">
          <div>
            <label htmlFor="sortBy">Sort By:</label>
            <select
              id="sortBy"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value={null}>None</option>
              <option value="firstName">First Name</option>
              <option value="lastName">Last Name</option>
              <option value="dob">Date of Birth</option>
            </select>
          </div>
          <div>
            <label>Sort Order:</label>
            <div>
              <button
                className={sortOrder === "asc" ? "selectedSortButton" : ""}
                onClick={() => setSortOrder("asc")}
              >
                <FontAwesomeIcon icon={faSortUp} />
                <div className="patientsActionButtonText">Ascending</div>
              </button>
              <button
                className={sortOrder === "desc" ? "selectedSortButton" : ""}
                onClick={() => setSortOrder("desc")}
              >
                <FontAwesomeIcon icon={faSortDown} />
                <div className="patientsActionButtonText">Descending</div>
              </button>
            </div>
          </div>
          <div>
            <label>Filter By Flags:</label>
            <div>
              {Object.keys(selectedFlags).map((flag) => (
                <div key={flag}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    id={flag}
                    checked={selectedFlags[flag]}
                    onChange={() => handleFlagChange(flag)}
                  />
                  <label htmlFor={flag}>{flag}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <button onClick={handleApplySortFilter}>Apply</button>
      </Modal>
      <button
        className={`scrollToTopButton ${showScrollToTop ? "visible" : ""}`}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </>
  );
}

export default PatientList;
